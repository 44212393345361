<template>
  <div class="col-5  p-0 survey-detail">
    <h3 v-if="screen">Экран "<span>{{ screen.screen.title }}</span>" Опроса "<span>{{ screen.survey_title }}</span>"</h3>
 
    <!-- Create Element Button -->
    <div class="flex justify-content-between">
      <Button label="К экранам" @click="goBack" class="p-button-secondary" />
      <Button label="Добавить элемент" icon="fa fa-plus" @click="showElementTypeDialog = true" />
    </div>
       
    <!-- Elements List -->
    <ul class="element-list" v-if="elements.length > 0">
      <li v-for="element in elements" :key="element.id" class="element-item flex justify-content-between align-items-center">
        <div class="element-info flex align-items-center">
          <InputNumber v-model="element.order" showButtons buttonLayout="vertical" style="width: 2.2rem" :min="1" :max="99" @blur="updateOrder(element.id, element.order)">
            <template #incrementbuttonicon>
                <span class="fa fa-plus" />
            </template>
            <template #decrementbuttonicon>
                <span class="fa fa-minus" />
            </template>
          </InputNumber>
          <div class="screen-element">
            <p v-if="element.element_type === 'TEXT'">Текстовый элемент</p>
            <p v-if="element.element_type === 'QUESTION'"><span style="font-weight:bold">[{{ element.question.question_sku }}] </span>Вопрос <span class="question-type">({{ element.question.question_type }}<span v-if="element.question.grid_type"> - {{ element.question.grid_type }}</span>)</span></p>

            <div v-if="element.element_type === 'TEXT'">{{ getTextPreview(element.content) }}...</div>
            <div v-if="element.element_type === 'QUESTION'">
              {{ element.question.title }}
            </div>
            <div class="flex">
              <span v-if="element.element_type === 'QUESTION'" class="m-0 mr-2 subtext">Ответов:{{ element.question.answer_count }}</span>
              <span v-if="element.rule_count > 0" class="m-0 subtext">Правил:{{ element.rule_count }}</span>
            </div>
          </div>
        </div>
        <div class="element-actions">
          <Button @click="openEditElementDialog(element)" icon="fa fa-edit" severity="link" />
          <Button @click="confirmElementDeletion(element.id)" icon="fa fa-trash" severity="link" class="p-button-danger" />
        </div>
      </li>
    </ul>
    <ul v-else class="p-0" style="list-style: none;">
      <li class="p-0">Элементов у этого экрана ещё нет</li>
    </ul>

    <!-- Element Type Selection Dialog -->
    <Dialog 
      v-model:visible="showElementTypeDialog" 
      header="Выберите тип элемента" 
      :modal="true" 
      :closable="true"
      
    >
      <div class="m-1 grid d-flex justify-content-between">
        <Button label="Текст" @click="showTextEditorDialog = true; showElementTypeDialog = false" class="p-button-secondary" />
        <Button label="Вопрос" @click="showQuestionEditorDialog = true; showElementTypeDialog = false" class="p-button-secondary" />
      </div>
    </Dialog>

    <!-- Text Editor Dialog -->
    <Dialog 
      v-model:visible="showTextEditorDialog" 
      header="Создать текстовый элемент" 
      :modal="true" 
      :closable="false"
      style="width:550px;height:550px"
    >
      <div class="new-content">
        <Editor
          api-key="1c1sdv8n0ka4ffqd38uxmqasifxfa3n58208mwyzw2bsm7w1"
          :init="{
            skin: 'naked',
            icons: 'small',
            toolbar_location: 'bottom',
            plugins: 'lists code table codesample link',
            toolbar: 'blocks | bold italic underline strikethrough bullist link codesample',
            menubar: false,
            statusbar: false
          }"
          v-model:content="newTextContent"
          @change="updateContent"
        />
      </div>
      <div class="flex justify-content-between">
        <Button label="Отмена" @click="showTextEditorDialog = false" class="p-button-secondary" />
        <Button label="Сохранить" @click="createTextElement" />
      </div>
    </Dialog>

    <!-- Question Editor Dialog -->
    <Dialog 
      v-model:visible="showQuestionEditorDialog" 
      header="Создать вопрос" 
      :modal="true" 
      :closable="false"
      style="width:350px;"
    >
      <div class="p-field">
        <label for="question-title">Название вопроса</label>
        <InputText style="width:100%;" id="question-title" v-model="newQuestion.title" required />
      </div>
      <div class="p-field">
        <label for="question-type">Тип вопроса</label>
        <Dropdown style="width:100%;" id="question-type" v-model="newQuestion.type" :options="questionTypes" optionLabel="name" placeholder="Выберите тип" />
      </div>

        <!-- настройки для типа "GRID" -->
        <div class="p-field" v-if="newQuestion.type.name === 'Сетка'">
          <label for="grid-type">Тип сетки</label>
          <Dropdown 
            id="grid-type"
            style="width:100%;" 
            v-model="newQuestion.gridType" 
            :options="gridTypes" 
            optionLabel="name" 
            placeholder="Выберите тип сетки" 
          />
        </div>

      <div v-if="newQuestion.type === 'REFERENCE'">
        <h4>Настройки справочника</h4>
        <!-- Add logic for selecting reference and column -->
        <Dropdown v-model="newQuestion.reference" :options="references" optionLabel="name" placeholder="Выберите справочник" />
        <Dropdown v-model="newQuestion.column" :options="columns" optionLabel="name" placeholder="Выберите колонку" />
      </div>

      <div class="flex justify-content-between">
        <Button label="Отмена" @click="showQuestionEditorDialog = false" class="p-button-secondary" />
        <Button 
          label="Сохранить" 
          @click="createQuestionElement" 
          :disabled="!isQuestionValid" 
        />
      </div>
    </Dialog>

    <!-- Edit Text Element Dialog -->
    <Dialog 
      v-model:visible="showEditElementDialog" 
      header="Редактировать элемент" 
      :modal="true" 
      :closable="false"
      style="min-width:1300px;min-height:550px"
    >
      <div class="grid">
        <div class="col-6">
          <div v-if="editElement.element_type === 'TEXT'" class="edit-content">
            <Editor
              api-key="1c1sdv8n0ka4ffqd38uxmqasifxfa3n58208mwyzw2bsm7w1"
              :init="{
                skin: 'naked',
                icons: 'small',
                toolbar_location: 'bottom',
                plugins: 'lists code table codesample link',
                toolbar: 'blocks | bold italic underline strikethrough bullist link codesample',
                menubar: false,
                statusbar: false
              }"
              v-model="editElement.content"
            />
          </div>
          <div class="flex justify-content-between">
            <Button label="Отмена" @click="showEditElementDialog = false" class="p-button-secondary" />
            <Button label="Сохранить" @click="updateScreenElement" />
          </div>
        </div>
        <div class="col-5 col-offset-1">
          <RuleForm :elementId="editElement.id" />
        </div>
      </div>
    </Dialog>

    <!-- Edit Question Dialog -->
    <Dialog 
      v-model:visible="showEditQuestionDialog" 
      :modal="true" 
      :closable="true"
      @hide="handleDialogHide"
      style="min-width:1300px;min-height:550px"
    >
      <template #header>
        <div><h3>Редактировать <b>{{ editElement.question.question_type }}<span style="color:inherit;" v-if="editElement.question.grid_type"> - {{ editElement.question.grid_type }}</span></b> вопрос</h3></div>
      </template>
    <QuestionDetails :elementId="editElement.id" :question="editElement.question" />
    </Dialog>

    <!-- Confirmation Dialog -->
    <Dialog 
      v-model:visible="showConfirmDialog" 
      header="Подтверждение удаления" 
      :modal="true" 
      :closable="false"
    >
      <p>Вы уверены, что хотите удалить этот элемент?</p>
      <div class="flex justify-content-between">
        <Button label="Отмена" @click="showConfirmDialog = false" class="p-button-secondary" />
        <Button label="Удалить" @click="confirmElementDeletionConfirmed" class="p-button-danger" style="color:white;" />
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getScreen, createElement, getElements, deleteElement, updateElement, updateElementOrder } from '../../services/apiService';
import RuleForm from '../../components/admin-editor/RuleForm.vue';
import QuestionDetails from '../../components/admin-editor/QuestionDetails.vue'
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Editor from '@tinymce/tinymce-vue';

const route = useRoute();
const router = useRouter();
const screen = ref(null);
const elements = ref([]);
const showElementTypeDialog = ref(false);
const showTextEditorDialog = ref(false);
const showQuestionEditorDialog = ref(false);
const showEditElementDialog = ref(false);
const showEditQuestionDialog = ref(false);
const showConfirmDialog = ref(false);
const elementToDelete = ref(null);
const newTextContent = ref('');
const newQuestion = ref({
  title: '',
  type: '',
  reference: '',
  gridType: null,
});
const editElement = ref({ id: '', element_type: '', content: '', question: { title: '', sku: '' }, questionType: '' });
const questionTypes = [
  { name: 'Одиночный выбор', value: 'SINGLE' },
  { name: 'Множественный выбор', value: 'MULTIPLE' },
  { name: 'Численное значение', value: 'NUM' },
  { name: 'Градация', value: 'SCALE' },
  { name: 'Сетка', value: 'GRID' },
  { name: 'Справочник', value: 'REFERENCE' },
];

const gridTypes = [
  { name: 'Сетка с одиночным выбором', value: 'SINGLE' },
  { name: 'Сетка с множественным выбором', value: 'MULTIPLE' },
  { name: 'Сетка с градацией', value: 'SCALE' },
  { name: 'Сложная сетка', value: 'CUSTOM' },
];

const references = ref([]); // Для списка справочников
const columns = ref([]); // Для списка колонок

const handleDialogHide = async () => {
  await fetchElements();
};

const fetchScreen = async () => {
  try {
    const response = await getScreen(route.params.surveyId, route.params.screenId);
    screen.value = response.data;
    await fetchElements(); // Загрузите элементы после получения экрана
  } catch (error) {
    console.error('Error fetching screen:', error);
  }
};

const fetchElements = async () => {
  try {
    const response = await getElements(route.params.screenId);
    elements.value = response.data;
  } catch (error) {
    console.error('Error fetching elements:', error);
  }
};

const getTextPreview = (htmlContent) => {
  const div = document.createElement('div');
  div.innerHTML = htmlContent;
  return div.textContent.slice(0, 100);
};

const updateContent = (event) => {
  newTextContent.value = event.target.getContent();
};

const createTextElement = async () => {
  try {
    const elementData = {
      element_type: 'TEXT',
      content: newTextContent.value,
      screen: screen.value.screen.id
    };
    await createElement(elementData);
    await fetchElements(); // Обновить список элементов
    showTextEditorDialog.value = false;
    newTextContent.value = ''; // Очистка данных
  } catch (error) {
    console.error('Ошибка создания текстового элемента:', error);
  }
};

const isQuestionValid = computed(() => {
  if (newQuestion.value.type === 'REFERENCE') {
    return newQuestion.value.reference && newQuestion.value.column;
  }
  return newQuestion.value.title.trim() !== '' && newQuestion.value.type !== '';
});

const createQuestionElement = async () => {
  if (!isQuestionValid.value) {
    alert('Вопрос не валиден');
    return;
  }

  try {
    const elementData = {
      screen: route.params.screenId,
      element_type: 'QUESTION',
      question: newQuestion.value.title,
      questionType: newQuestion.value.type.value,
      reference: newQuestion.value.reference || null,
    };
    if (newQuestion.value.gridType) {
      elementData.gridType = newQuestion.value.gridType.value;
    }
    else {
      elementData.gridType = null;
    }

    await createElement(elementData);
    await fetchElements();
  } catch (error) {
    console.error('Error creating question element:', error);
  } finally {
    showQuestionEditorDialog.value = false;
    newQuestion.value = { title: '', type: '', reference: '', gridType: null }; // Очищаем форму
  }
};


const fetchReferences = async () => {
  // Логика для получения списка справочников
};

const fetchColumns = async () => {
  // Логика для получения списка колонок
};

const openEditElementDialog = (element) => {
  editElement.value = { ...element };
  if (element.element_type === 'TEXT') {
    showEditElementDialog.value = true;
  } else if (element.element_type === 'QUESTION') {
    showEditQuestionDialog.value = true;
  }
};

const updateScreenElement = async () => {
  try {
    await updateElement(editElement.value.id, editElement.value);
    await fetchElements();
  } catch (error) {
    console.error('Error updating element:', error);
  } finally {
    showEditElementDialog.value = false;
    editElement.value = { id: '', element_type: '', content: '', question: '', questionType: '' };
  }
};
const confirmElementDeletion = (id) => {
  elementToDelete.value = id;
  showConfirmDialog.value = true;
};

const confirmElementDeletionConfirmed = async () => {
  try {
    await deleteElement(elementToDelete.value);
    await fetchElements();
  } catch (error) {
    console.error('Error deleting element:', error);
  } finally {
    showConfirmDialog.value = false;
    elementToDelete.value = null;
  }
};

const updateOrder = async (id, order) => {
  try {
    await updateElementOrder(id, order);
    await fetchElements();
  } catch (error) {
    console.error('Error updating order:', error);
  }
};

const goBack = () => {
  router.push(`/surveys/${route.params.surveyId}`);
};

onMounted(async () => {
  await fetchScreen();
  await fetchReferences();
  await fetchColumns();
});
</script>

<style scoped>
h3 span {
  color: var(--p-button-link-color);
}
</style>

<style scoped>
h3 span {
  color: var(--p-button-link-color);
}
.new-content, .edit-content {
  border: 1px solid #ccc;
  margin-bottom: 15px;
}
.screen-element {

    margin-left: 10px;
}
.screen-element p {
  text-decoration: none;
    color: #007bff;
    font-weight: 600;
    margin-bottom: 0;
    margin-top:0;
}
.element-content p {
margin: 0!important;
}
.survey-detail {
  padding: 20px;
}
.element-list {
  list-style: none;
  padding: 0;
}
.element-item {
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.element-actions {
  display: flex;
}
.p-button-danger {
  color: #dc3545;
}
.element-item a {
  text-decoration: none;
  color: #007bff;
  font-weight: 600;
  margin-left:10px;
}
.question-type {
  font-size: 14px;
    margin-left: 0;
    color: #6c757d;
    font-weight: 400;
}
.subtext {
  display: block;
  color: #6c757d;
  font-size: 0.7rem !important;
  margin-top:2px!important;
}
</style>
