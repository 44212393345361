<template>
  <div id="public" :class="{ 'grid': isCheck }">
    <main :class="{ 'col-offset-2 col-8': isCheck, 'p-3': !isCheck }">
      <div v-if="isValidParams">
        <router-view></router-view>
      </div>
      <div v-else>
        <h2>Некорректные параметры опроса</h2>
      </div>
    </main>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

// Получаем параметры из URL
const route = useRoute();
const courseId = route.query.courseId;
const moduleId = route.query.moduleId;
const studentId = route.query.studentId;
const isCheck = route.query.isCheck;

// Проверяем корректность параметров
const isValidParams = computed(() => {
  return courseId !== null && moduleId !== null && studentId !== null;
});
</script>
<style>
@media screen and (max-width:768px) {
  body, h4 {
    font-size: 12px;
  }
  .grid-table tr td, .grid-table tr th {
    padding: 5px!important;
}
.rating-circle[data-v-5706c1e4] {
    width: 12px!important;
    height: 12px!important;
    font-size: 9px!important;
}
.p-button {
  font-size: 12px!important;
}
.num-block .p-inputnumber-input, .grid-table .p-inputnumber-input {
    width: 45px!important;
    font-size: 12px!important;
}
}
</style>
