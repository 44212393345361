import axios from 'axios';
import { API_URL } from '../config';

// Создание экземпляра axios с базовым URL
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

//## Опросы ##// 
export const getSurveys = () => api.get('/surveys-list');
export const createSurvey = (data) => api.post('/surveys', data);
export const updateSurvey = (id, data) => api.put(`/surveys/${id}`, data);
export const deleteSurvey = (id) => api.delete(`/surveys/${id}`);

export const updateScreenOrder = (id, order) => {
  return axios.patch(`${API_URL}/screens/update-order`, { id, order });
};

export const getSurvey = (id) => {
  if (!id) {
    throw new Error('Survey ID is required');
  }
  return axios.get(`${API_URL}/surveys/${id}`);
};

//## Экраны ##// 
export const getScreens = (surveyId) => axios.get(`${API_URL}/screens/${surveyId}/`);
export const createScreen = (data) => api.post('/screens', data, {
  headers: {
    'Content-Type': 'application/json'
  }
});
export const deleteScreen = (screenId) => axios.delete(`${API_URL}/screens/${screenId}`);
export const updateScreen = (screenId, data) => axios.put(`${API_URL}/screens/${screenId}`, data);

export const getScreen = (surveyId, screenId) => {
  if (!surveyId || !screenId) {
    throw new Error('Survey ID and Screen ID are required');
  }
  return axios.get(`${API_URL}/surveys/${surveyId}/screens/${screenId}`);
};

// ## Элементы ## //
export const getElements = (screenId) => axios.get(`${API_URL}/screen/${screenId}/elements/`);
export const createElement = (data) => api.post(`/screen/${data.screen}/elements/create/`, data);
export const deleteElement = (elementId) => axios.delete(`${API_URL}/elements/${elementId}/delete/`);
export const updateElement = (elementId, data) => axios.patch(`${API_URL}/elements/${elementId}/update/`, data);
export const updateElementOrder = (id, order) => api.patch(`${API_URL}/elements/update-order`, { id, order });

// ## Ответы ## //
export const getAnswers = (questionId) => axios.get(`${API_URL}/answers`, {
  params: {
    question_id: questionId
  }
});
export const createAnswer = (data) => api.post('/answers/create', data);
export const updateAnswer = (id, data) => api.put(`/answers/${id}/update`, data);
export const deleteAnswer = (id) => axios.delete(`${API_URL}/answers/${id}/delete`);
export const updateAnswerOrder = (data) => api.patch(`${API_URL}/answers/update-order`, data);

// ## Правила и условия ## //

// Функция для получения всех вопросов
export const getAllQuestions = () => api.get('/questions/');

export const getRules = (elementId) => {
  return axios.get(`${API_URL}/rules?element_id=${elementId}`);
};
export const createRule = (data) => api.post('/rules', data);
export const deleteRule = (id) => api.delete(`/rules/${id}/`);

// ## Публичная часть ## //
export const getPublicSurvey = (surveyId, moduleId) => {
  return axios.get(`${API_URL}/survey/${surveyId}/${moduleId}/`);
};

// ## Справочники ## //
export const uploadReferenceFile = (formData) => {
  return axios.post(`${API_URL}/ref-upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
export const getReferenceFiles = () => {
  return axios.get(`${API_URL}/ref-list`);
};
export const updateReferenceFileName = (fileId, name) => {
  return api.patch(`/ref-update/${fileId}/`, { name });
};
export const deleteReferenceFile = (fileId) => {
  return api.delete(`/ref-delete/${fileId}/`);
};

export const getReferenceData = (referenceFileId, columnName, filter_answer_reference_id = null) => {
  let url = `/ref-fetch/${referenceFileId}/columns/${columnName}/`;
  if (filter_answer_reference_id) {
    url += `filter/${filter_answer_reference_id}/`;
  }
  return api.get(url);
};

export const getSurveyReferences = (survey_id, reference_file) => {
  return axios.get(`${API_URL}/ref-new-fetch/${survey_id}/${reference_file}/`);
};




// ## Подвопросы ## //
export const getSubQuestions = (questionId) => axios.get(`${API_URL}/sub-questions`, {
  params: {
    question_id: questionId
  }
});

export const createSubQuestion = (data) => axios.post(`${API_URL}/sub-questions/create`, data);

export const updateSubQuestion = (id, data) => axios.patch(`${API_URL}/sub-questions/${id}/update`, data);

export const deleteSubQuestion = (id) => axios.delete(`${API_URL}/sub-questions/${id}/delete`);

export const updateSubQuestionOrder = (id, order) => 
  axios.patch(`${API_URL}/sub-questions/update-order`, { id, order });


// ## Прогресс студента ## //
export const saveSurveyData = async (data) => {
  try {
    const response = await api.post('students/save-progress/', {
      studentId: data.studentId, // ID студента
      surveyId: data.surveyId,   // ID опроса
      responses: data.responses,  // Ответы на вопросы
      currentScreenId: data.currentScreenId,
      isSurveyCompleted: data.isSurveyCompleted
    });
    return response.data;
  } catch (error) {
    console.error('Error saving survey data:', error);
    throw error;
  }
};

export const getSurveyProgress = async (surveyId, studentId) => {
  try {
    const response = await api.get(`students/${studentId}/survey-detail/${surveyId}/`);
    console.log('API response:', response); // Логируем весь ответ
    return response.data;
  } catch (error) {
    console.error('Error fetching survey progress:', error);
    throw error;
  }
};

// REF Filters
export const getFilters = async (ref_id) => {
  const response = await api.get(`filters/${ref_id}/`);
  return response.data;
};

// Применить фильтр
export const applyFilter = async (answer_id, answer_ref) => {
  try {
    const response = await api.post('add-filter/', {
      answer_id: answer_id,
      answer_ref: answer_ref,
    });
    return response.data;
  } catch (error) {
    console.error('Error saving survey data:', error);
    throw error;
  }
};


// ## Прогресс студентов ## //
export const getStudentProgress = () => {
  return api.get('/student-progress/');
};

export const getStudentSurveyAnswers = (studentId, surveyId) => {
  return api.get(`/student/${studentId}/survey-detail/${surveyId}/`);
};