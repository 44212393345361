<template>
  <div class="grid">
    <div :class="{ 'col-8': isCheck, 'col': !isCheck }" style="max-width:100%;">
      <span v-if="errorMessage">Проверьте CourseId, ModuleID и наличие StudentID</span>
      <div v-if="isValidParams" class="survey-page m-0 p-0">
        <h1 v-if="showDebugPanel">{{ survey.title }}</h1>
        <div v-if="isDataLoaded">
          <div v-if="currentScreen">
            <div v-if="!isSurveyCompleted" class="screen-content">
              <h2 v-if="showDebugPanel">{{ currentScreen.title }}</h2>
              <b v-if="studentId === '1'">Это тестовый студент - данные не сохраняются</b>
              <!-- Отображение элементов экрана -->
              <div v-for="element in filteredElements" :key="element.id" class="screen-element">

                <!-- Отображение текстовых элементов -->
                <div v-if="element.element_type === 'TEXT'" v-html="element.content"></div>
                <!-- Отображение вопросов -->
                <div v-if="element.element_type === 'QUESTION'">
                  <h4 class="mb-2 mt-5" :class="{ 'question-error': !isQuestionAnswered(element.question.id) }">
                    {{ element.question.title }}
                  </h4>
                  <!-- Определение типа сетки -->
                  <div v-if="element.question.question_type === 'GRID' && element.question.child_questions"
                    class="table-container">
                    <!-- Определяем колонки и ряды -->
                    <table class="grid-table">
                      <thead>
                        <tr>
                          <th></th>
                          <th v-for="answer in element.question.answers" :key="answer.id">{{ answer.title }}</th>
                        </tr>
                      </thead>
                      <tbody v-if="!element.question.has_dynamic_rows">
                        <tr v-for="rowQuestion in element.question.child_questions" :key="rowQuestion.id">
                          <td :class="{ 'highlight-red': unansweredQuestions.has(rowQuestion.id) }">{{ rowQuestion.title
                            }}</td>
                          <td v-for="answer in element.question.answers" :key="answer.id">
                            <!-- Логика для обычных типов гридов -->
                            <div v-if="element.question.grid_type === 'SINGLE'" class="text-center">
                              <RadioButton :inputId="`grid_${rowQuestion.id}_${answer.id}`" :value="answer.id"
                                v-model="selectedAnswers[rowQuestion.id]" />
                            </div>
                            <div v-else-if="element.question.grid_type === 'MULTIPLE' && !answer.is_none"
                              class="text-center">
                              <Checkbox :inputId="`grid_${rowQuestion.id}_${answer.id}`" :value="answer.id"
                                v-model="selectedAnswers[rowQuestion.id]" :disabled="isNoneSelected(rowQuestion.id)" />
                            </div>
                            <div v-else-if="element.question.grid_type === 'NUM' && !answer.is_none">
                              <InputNumber v-model="selectedAnswers[rowQuestion.id]" :min="answer.min_value"
                                :max="answer.max_value" mode="decimal" showButtons buttonLayout="horizontal"
                                 />
                            </div>
                            <div v-else-if="element.question.grid_type === 'SCALE' && !answer.is_none">
                              
                              <ScaleTemplate v-model="selectedAnswers[rowQuestion.id]" :stars="answer.max_value"
                                :isNone="isNoneSelected(rowQuestion.id)" />
                            </div>
                            <div v-else-if="answer.is_none && element.question.grid_type != 'SINGLE'">
                              <Checkbox :inputId="`answer_${answer.id}`" :value="answer.id"
                                v-model="selectedAnswers[rowQuestion.id]"
                                @change="handleNoneAnswerChange(rowQuestion.id, answer.id)"
                                :checked="isNoneSelected(rowQuestion.id)" class="is-none" />
                            </div>
                            <!-- Логика для Custom гридов -->
                            <template v-else-if="element.question.grid_type === 'CUSTOM'">

                              <div v-if="answer.answer_type === 'REFERENCE'">
                                <Dropdown v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                  :options="referenceOptions[element.question.id + '_' + answer.id]" filter
                                  optionLabel="label" optionValue="value" placeholder="Выберите значение" />
                              </div>
                              <div v-else-if="answer.answer_type === 'NUM'">
                                <InputNumber v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                  :min="answer.min_value" :max="answer.max_value" mode="decimal" showButtons
                                  buttonLayout="horizontal"  />
                              </div>
                              <div v-else-if="answer.answer_type === 'SCALE'">
                                <ScaleTemplate v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                  :stars="answer.max_value" />
                              </div>
                            </template>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <!-- HARDCODE Q-33  -->
                        <template v-if="element.question.id === 225">
                          <template
                            v-for="(rowQuestion, rowIndex) in element.question.child_questions.slice(0, element.question.visibleRows)"
                            :key="rowQuestion.id">
                            <!-- Добавляем строку с дропдаунами перед каждым 9-м элементом -->
                            <tr v-if="rowIndex % 9 === 0">
                              <td :colspan="element.question.answers.length + 1">
                                <b style="display:block">Выберите компанию и Торговое наименование</b>
                                <div class="dropdown-container flex md:flex-row flex-column">

                                  <Dropdown style="width:250px!important"
                                    v-model="dropdownValues['dropdownOne_' + rowIndex]"
                                    :options="referenceOptions[9999]" filter optionLabel="label" optionValue="value"
                                    placeholder="Компания" class="mt-3 mb-0 mr-3"
                                    @change="handleFirstDropdownChange(rowIndex, rowQuestion.id)" />
                                  <Dropdown style="width:250px!important;"
                                    v-model="dropdownValues['dropdownTwo_' + rowIndex]"
                                    :options="referenceOptions[88880 + rowIndex]" filter optionLabel="label"
                                    optionValue="value" placeholder="Торговое наименование" class="mt-3 mb-0 mr-3"
                                    @change="handleSecondDropdownChange(rowIndex, rowQuestion.id)" />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <InputText :class="{ 'highlight-red': unansweredQuestions.has(rowQuestion.id) }"
                                  style="border:none;" v-model="selectedAnswers[rowQuestion.id]"
                                  :value="referenceOptions[element.question.id] && referenceOptions[element.question.id][rowIndex % referenceOptions[element.question.id].length] ? referenceOptions[element.question.id][rowIndex % referenceOptions[element.question.id].length].label : ''">
                                </InputText>
                              </td>
                              <td v-for="answer in element.question.answers" :key="answer.id">
                                <!-- Логика для обычных типов гридов -->
                                <div v-if="element.question.grid_type === 'SINGLE'" class="text-center">
                                  <RadioButton :inputId="`grid_${rowQuestion.id}_${answer.id}`" :value="answer.id"
                                    v-model="selectedAnswers[rowQuestion.id]" />
                                </div>
                                <div v-else-if="element.question.grid_type === 'MULTIPLE'" class="text-center">
                                  <Checkbox :inputId="`grid_${rowQuestion.id}_${answer.id}`" :value="answer.id"
                                    v-model="selectedAnswers[rowQuestion.id]" />
                                </div>
                                <div v-else-if="element.question.grid_type === 'NUM'">
                                  <InputNumber v-model="selectedAnswers[rowQuestion.id]" :min="answer.min_value"
                                    :max="answer.max_value" mode="decimal" showButtons buttonLayout="horizontal"
                                     />
                                </div>
                                <div v-else-if="element.question.grid_type === 'SCALE'">
                                  <ScaleTemplate v-model="selectedAnswers[rowQuestion.id]" :stars="answer.max_value" />
                                </div>
                                <!-- Логика для Custom гридов -->
                                <template v-else-if="element.question.grid_type === 'CUSTOM'">
                                  <div v-if="answer.answer_type === 'REFERENCE' && !element.question.has_dynamic_rows">
                                    <Dropdown v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                      :options="referenceOptions[element.question.id + '_' + answer.id]" filter
                                      optionLabel="label" optionValue="value" placeholder="Выберите значение" />
                                  </div>
                                  <div v-if="answer.answer_type === 'REFERENCE' && element.question.has_dynamic_rows"
                                    style="display:none;">
                                    <Dropdown v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                      :options="referenceOptions[element.question.id + '_' + rowQuestion.id + '_' + answer.id]"
                                      filter optionLabel="label" optionValue="value" placeholder="Выберите значение"
                                      @change="filterReferenceDataByColumn(element.question.id, rowQuestion.id, answer.id)" />
                                  </div>
                                  <div v-else-if="answer.answer_type === 'NUM'">
                                    <InputNumber v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                      :min="answer.min_value" :max="answer.max_value" mode="decimal" showButtons
                                      buttonLayout="horizontal"  />
                                  </div>
                                  <div v-else-if="answer.answer_type === 'SCALE'">
                                    <ScaleTemplate v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                      :stars="answer.max_value" />
                                  </div>
                                </template>
                              </td>
                            </tr>
                          </template>
                        </template>
                        <template v-else>
                          <tr
                            v-for="(rowQuestion) in element.question.child_questions.slice(0, element.question.visibleRows)"
                            :key="rowQuestion.id">
                            <td>
                              <Dropdown v-model="selectedAnswers[rowQuestion.id]"
                                :options="referenceOptions[element.question.id]" filter optionLabel="label"
                                optionValue="value" placeholder="Выберите значение"
                                @change="filterReferenceDynamicDataByKey(element.question.id, rowQuestion.id)" :disabled="disabledDropdowns[rowQuestion.id]"  />
                            </td>
                            <td v-for="answer in element.question.answers" :key="answer.id">
                              <!-- Логика для обычных типов гридов -->
                              <div v-if="element.question.grid_type === 'SINGLE'" class="text-center">
                                <RadioButton :inputId="`grid_${rowQuestion.id}_${answer.id}`" :value="answer.id"
                                  v-model="selectedAnswers[rowQuestion.id]" />
                              </div>
                              <div v-else-if="element.question.grid_type === 'MULTIPLE'" class="text-center">
                                <Checkbox :inputId="`grid_${rowQuestion.id}_${answer.id}`" :value="answer.id"
                                  v-model="selectedAnswers[rowQuestion.id]" />
                              </div>
                              <div v-else-if="element.question.grid_type === 'NUM'">
                                <InputNumber v-model="selectedAnswers[rowQuestion.id]" :min="answer.min_value"
                                  :max="answer.max_value" mode="decimal" showButtons buttonLayout="horizontal"
                                  />
                              </div>
                              <div v-else-if="element.question.grid_type === 'SCALE'">
                                
                                <ScaleTemplate v-model="selectedAnswers[rowQuestion.id]" :stars="answer.max_value" />
                              </div>
                              <!-- Логика для Custom гридов -->
                              <template v-else-if="element.question.grid_type === 'CUSTOM'">
                                <div v-if="answer.answer_type === 'REFERENCE' && !element.question.has_dynamic_rows">
                                  <Dropdown v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                    :options="referenceOptions[element.question.id + '_' + answer.id]" filter
                                    optionLabel="label" optionValue="value" placeholder="Выберите значение" />
                                </div>
                                <div v-if="answer.answer_type === 'REFERENCE' && element.question.has_dynamic_rows">
                                  <Dropdown v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                    :options="referenceOptions[element.question.id + '_' + rowQuestion.id + '_' + answer.id]"
                                    filter optionLabel="label" optionValue="value" placeholder="Выберите значение"
                                    @change="filterReferenceDataByColumn(element.question.id, rowQuestion.id, answer.id)" :disabled="disabledDropdowns[rowQuestion.id + '_' + answer.id]"  />
                                </div>
                                <div v-else-if="answer.answer_type === 'NUM'">
                                  <InputNumber v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                    :min="answer.min_value" :max="answer.max_value" mode="decimal" showButtons
                                    buttonLayout="horizontal"  />
                                </div>
                                <div v-else-if="answer.answer_type === 'SCALE'">
                                  <ScaleTemplate v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                    :stars="answer.max_value" />
                                </div>
                              </template>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                      <Button
                        v-if="element.question.has_dynamic_rows && element.question.visibleRows < element.question.child_questions.length"
                        @click="showMoreRows(element.question)" text>+ добавить ряд</Button>
                    </table>
                  </div>

                  <div v-if="element.question.question_type === 'REFERENCE'">

                    <div class="flex items-center mb-3" v-for="answer in element.question.answers" :key="answer.id">
                      <Dropdown v-if="!answer.is_none" v-model="selectedAnswers[element.question.id + '_' + answer.id]"
                        :options="referenceOptions[element.question.id + '_' + answer.id]" filter optionLabel="label"
                        optionValue="value" placeholder="Выберите значение"
                        @change="filterReferenceDataByKey(element.question.id, answer.id)"
                        :disabled="isNoneSelected(element.question.id)" />

                      <div v-if="answer.is_none">
                        <Checkbox :inputId="`answer_${answer.id}`" :value="answer.title"
                          v-model="selectedAnswers[element.question.id + '_' + answer.id]"
                          @change="handleNoneAnswerChange(element.question.id, answer.id)"
                          :checked="isNoneSelected(element.question.id)" />
                        <label :for="`answer_${answer.id}`" class="ml-2">{{ answer.title }}</label>
                      </div>
                    </div>
                  </div>
                  <div v-if="element.question.question_type === 'SINGLE'">
                    <div class="flex items-center mb-3" v-for="answer in element.question.answers" :key="answer.id">
                      <div v-if="answer.is_none">
                        <RadioButton :inputId="`answer_${answer.id}`" :value="answer.id"
                          v-model="selectedAnswers[element.question.id]" />
                        <label :for="`answer_${answer.id}`" class="ml-2">{{ answer.title }}</label>
                      </div>
                      <div v-else>
                        <RadioButton :inputId="`answer_${answer.id}`" :value="answer.id"
                          v-model="selectedAnswers[element.question.id]" />
                        <label :for="`answer_${answer.id}`" class="ml-2">{{ answer.title }}</label>
                      </div>
                    </div>
                    <div
                      v-if="element.question.answers.some(answer => answer.is_open) && selectedAnswers[element.question.id] === element.question.answers.find(answer => answer.is_open)?.id">
                      <InputText v-model="openAnswerText[element.question.id]" placeholder="Введите ответ" class="mt-1"
                        @input="updateOpenAnswerText(element.question.id, $event.target.value)" />
                    </div>
                  </div>
                  <div v-if="element.question.question_type === 'MULTIPLE'">
                    <div v-for="answer in element.question.answers" :key="answer.id" class="flex items-center mb-3">
                      <div v-if="answer.is_none">
                        <Checkbox :inputId="`answer_${answer.id}`" :value="answer.id"
                          v-model="selectedAnswers[element.question.id]"
                          @change="handleNoneAnswerChange(element.question.id, answer.id)"
                          :checked="isNoneSelected(element.question.id)" />
                        <label :for="`answer_${answer.id}`" class="ml-2">{{ answer.title }}</label>
                      </div>
                      <div v-else>
                        <Checkbox :inputId="`answer_${answer.id}`" :value="answer.id"
                          :disabled="isNoneSelected(element.question.id)"
                          v-model="selectedAnswers[element.question.id]" />
                        <label :for="`answer_${answer.id}`" class="ml-2">{{ answer.title }}</label>
                      </div>
                    </div>
                    <div
                      v-if="element.question.answers.some(answer => answer.is_open) && Array.isArray(selectedAnswers[element.question.id]) && selectedAnswers[element.question.id].includes(element.question.answers.find(answer => answer.is_open)?.id)">
                      <InputText v-model="openAnswerText[element.question.id]" placeholder="Введите ответ"
                        :disabled="isNoneSelected(element.question.id)" class="mt-1"
                        @input="updateOpenAnswerText(element.question.id, $event.target.value)" />
                    </div>
                  </div>
                  <div v-if="element.question.question_type === 'NUM' || element.question.question_type === 'SCALE'"
                    class="num-block">
                    <div v-for="answer in element.question.answers" :key="answer.id" class="flex items-center mb-3">
                      <div v-if="answer.is_none" class="flex items-center mb-3">
                        <RadioButton :inputId="`none_${answer.id}`" :value="0"
                          v-model="selectedAnswers[element.question.id]"
                          @change="handleNoneAnswerChange(element.question.id, element.question.answers[0]?.id)"
                          :checked="isNoneSelected(element.question.id)" />
                        <label :for="`none_${answer.id}`" class="ml-2">{{ answer.title }}</label>
                      </div>
                      <div v-if="answer.is_open" class="flex items-center mb-3">
                        <RadioButton :inputId="`none_${answer.id}`" :value="answer.id"
                          v-model="selectedAnswers[element.question.id]" />
                        <label :for="`none_${answer.id}`" class="ml-2">{{ answer.title }}</label>
                      </div>
                      <div v-if="element.question.question_type === 'NUM' && !answer.is_none && !answer.is_open">
                        <InputNumber v-model="selectedAnswers[element.question.id]" :min="answer.min_value"
                          :max="answer.max_value" mode="decimal" showButtons buttonLayout="horizontal"
                          
                          :disabled="isNoneSelected(element.question.id)" />
                      </div>
                      <div v-if="element.question.question_type === 'SCALE' && !answer.is_none && !answer.is_open">
                        <ScaleTemplate v-model="selectedAnswers[element.question.id]" :stars="answer.max_value" />
                      </div>
                      <div
                        v-if="answer.is_open && (selectedAnswers[element.question.id] || openAnswerText[element.question.id])">
                        <InputText v-model="openAnswerText[element.question.id]" placeholder="Введите ответ"
                          class="mt-1" @input="updateOpenAnswerText(element.question.id, $event.target.value)" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isSurveyCompleted" class="survey-completion">
              <p v-if="completionText">{{ completionText }}</p>
              <p v-else>Вы уже завершили опрос. Благодарим за прохождение</p>
              <Button @click="finishSurvey" label="Закончить" />
            </div>
            <div class="navigation-buttons" v-if="!isSurveyCompleted">
              <Button v-if="currentScreenIndex < screens.length - 1" @click="nextScreen" label="Следующий" />
              <Button v-else @click="finishSurvey" label="Завершить" />
            </div>
          </div>
        </div>
        <div v-else>
          <p v-if="isSurveyLoaded">Идёт загрузка данных опроса</p>
          <p v-else-if="isProgressLoaded">Идёт загрузка прогресса</p>
          <p v-else-if="isReferencesLoaded">Идёт загрузка справочников</p>
          <p v-else>Идёт загрузка</p>
          <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" fill="transparent" />
        </div>
      </div>
    </div>
    <div class="col-4" v-if="showDebugPanel">
      <div class="debug-panel">
        <h3>Панель отладки</h3>
        <p><strong>Опросник студента:</strong></p>
        <pre>{{ allQuestionsAndAnswers }}</pre>
        <p><strong>Скрытые вопроса на текущем экране:</strong></p>
        <pre>{{ hiddenQuestions }}</pre>
        <p><strong>Правила:</strong></p>
        <pre>{{ formattedRules }}</pre>
        <p><strong>Неотвеченные вопросы:</strong></p>
        <pre>{{ Array.from(unansweredQuestions) }}</pre>
        <p><strong>Информация текущего экрана:</strong></p>
        <pre>{{ currentScreen }}</pre>
        <p><strong>Что приходит из БАзы</strong></p>
        <p>{{ savedData.allQuestionsAndAnswers }}</p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import Button from 'primevue/button';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import ProgressSpinner from 'primevue/progressspinner';

import ScaleTemplate from '../../components/ScaleTemplate.vue'
import { getPublicSurvey, saveSurveyData, getSurveyProgress, getSurveyReferences } from '../../services/apiService';
const survey = ref({});
const screens = ref([]);
const currentScreenIndex = ref(0);
const route = useRoute();
const courseId = route.query.courseId;
const surveyId = ref(null)
const moduleId = route.query.moduleId;
const studentId = route.query.studentId;
const errorMessage = ref(false)
const isCheck = route.query.isCheck;
const isSurveyCompleted = ref(false);
const completionText = ref('');
const openAnswerText = ref({});
const isDataLoaded = ref(false);
const isSurveyLoaded = ref(false);
const isProgressLoaded = ref(false);
const isReferencesLoaded = ref(false);

const shouldCompleteSurvey = ref(false); // Если правило завершения опроса сработает
// Проверяем корректность параметров
const isValidParams = computed(() => {
  return courseId && moduleId && studentId;
});
const showDebugPanel = computed(() => isCheck !== undefined);
const currentScreen = computed(() => screens.value[currentScreenIndex.value]);
const currentScreenId = ref(null)
const selectedAnswers = ref({});
const unansweredQuestions = ref(new Set());
const filteredElements = computed(() => {
  if (!currentScreen.value) return [];
  const sortedElements = currentScreen.value.elements.slice().sort((a, b) => (a.order || 0) - (b.order || 0));
  return filterElementsByRules(sortedElements);
});
const referenceOptions = ref({});

const referenceData = ref({});
const savedData = ref([])
const allOfThem = ref([])

const getStudentSurveyProgress = async () => {
  isProgressLoaded.value = true
  try {
    const response = await getSurveyProgress(surveyId.value, studentId);
    const progressData = response;
    if (progressData) {
      savedData.value = progressData.allQuestionsAndAnswers;

      // Обновляем состояние текущего экрана и завершенности опроса
      currentScreenId.value = progressData.currentScreenId;
      isSurveyCompleted.value = progressData.isSurveyCompleted;
    }
    isProgressLoaded.value = false;
  } catch (error) {
    console.error('Error fetching student survey progress:', error);
    isProgressLoaded.value = false;
  }
};


const fetchSurveyData = async () => {
  try {
    isSurveyLoaded.value = true
    const response = await getPublicSurvey(courseId, moduleId);
    isSurveyLoaded.value = false
    survey.value = response.data.survey;
    screens.value = response.data.screens;
    surveyId.value = survey.value.id;

    // Загружаем прогресс студента
    await getStudentSurveyProgress();

    // Уникальные справочники
    const referenceFiles = new Set();
    screens.value.forEach(screen => {
      // Проходим по всем элементам на экране
      screen.elements.forEach(element => {
        if (element.element_type === 'QUESTION') {
          // Проверяем reference_file у самого вопроса
          if (element.question.reference_file) {
            referenceFiles.add(element.question.reference_file);
          }

          // Проходим по ответам вопроса
          element.question.answers.forEach(answer => {
            if (answer.reference_file) {
              referenceFiles.add(answer.reference_file);
            }
          });
        }
      });
    });
    // Преобразуем Set в массив
    const referenceFilesArray = Array.from(referenceFiles);
    // Загружаем данные для всех уникальных reference_files
    await getReferences(surveyId.value, referenceFilesArray);

    if (currentScreenId.value) {
      const index = screens.value.findIndex(screen => screen.id === currentScreenId.value);
      currentScreenIndex.value = index !== -1 ? index : 0; // Устанавливаем индекс экрана или первый экран, если прогресс не найден
    } else {
      currentScreenIndex.value = 0; // Начинаем с первого экрана
    }

    isDataLoaded.value = true;

    screens.value.forEach(screen => {
      screen.elements.forEach(element => {
        if (element.element_type === 'QUESTION') {
          element.question.answers = sortByOrder(element.question.answers);
          if (element.question.question_type !== 'GRID' && element.question.rotation) {
            element.question.answers = shuffledAnswers(element.question.answers);
          }
          if (element.question.question_type === 'GRID') {
            if (element.question.rotation) {
              element.question.child_questions = shuffleChildQuestions(element.question.child_questions);
            }
            if (element.question.has_dynamic_rows) {
              element.question.visibleRows = element.question.min_rows;
              element.question.child_questions = Array(element.question.max_rows).fill(null).map((_, index) => ({
                id: `${element.question.id}_${index + 1}`,
                title: `Строка ${index + 1}`,
                question_type: 'DYNAMIC',
              }));
            }
          }
        }
      });
    });

    for (const screen of screens.value) {
      for (const element of screen.elements) {
        if (element.element_type === 'QUESTION' && element.question.question_type === 'REFERENCE') {
          for (const answer of element.question.answers) {
            const referenceFileId = answer.reference_file;
            const columnName = answer.reference_column;
            if (referenceFileId && columnName) {
              await loadReferenceData(`${element.question.id}_${answer.id}`, referenceFileId, columnName);
            }
          }
        } else if (element.element_type === 'QUESTION' && element.question.question_type === 'GRID') {
          for (const answer of element.question.answers || []) {
            if (answer.answer_type === 'REFERENCE') {
              const referenceFileId = answer.reference_file;
              const columnName = answer.reference_column;
              if (referenceFileId && columnName) {
                if (element.question.grid_type === 'CUSTOM' && element.question.has_dynamic_rows) {
                  for (const childRow of element.question.child_questions) {
                    await loadReferenceData((element.question.id + '_' + childRow.id + '_' + answer.id), referenceFileId, columnName);
                  }
                } else {
                  await loadReferenceData((element.question.id + '_' + answer.id), referenceFileId, columnName);
                }
              }
            }
            if (element.question.has_dynamic_rows) {
              const referenceFileId = element.question.reference_file;
              const columnName = element.question.reference_column;
              if (referenceFileId && columnName) {
                await loadReferenceData((element.question.id), referenceFileId, columnName);
              }
            }
          }
        }
      }
    }
    // HARDCODE Q-33
    await loadReferenceData(9999, 4, 17)
    await loadReferenceData(88880, 4, 19)
    await loadReferenceData(88889, 4, 19)
    await loadReferenceData(88898, 4, 19)
    await loadReferenceData(88907, 4, 19)
    await loadReferenceData(88916, 4, 19)
    await loadReferenceData(88925, 4, 19)
    // HARDCODE Q-33

  } catch (error) {
    console.error('Error fetching survey:', error);
    errorMessage.value = true;
  }
};

const getReferences = async (surveyId, referenceFilesArray) => {
  isReferencesLoaded.value = true;
  try {
    // Создаем объект для хранения результатов
    const results = {};

    // Создаем и выполняем запросы по каждому reference_file
    for (const referenceFileId of referenceFilesArray) {

      const response = await getSurveyReferences(surveyId, referenceFileId);
      results[referenceFileId] = response.data; // Сохраняем данные по каждому reference_file
    }
    referenceData.value = results;
    isReferencesLoaded.value = false;
  } catch (error) {
    console.error("Ошибка при получении данных справочников:", error);
  }
};

const loadReferenceData = async (sku, referenceFileId, columnNameId, filterColumn, filterKey) => {
  try {
    // Получаем данные из referenceData по referenceFileId
    const reference = referenceData.value[referenceFileId];
    if (!reference) {
      console.error(`No data found for referenceFileId ${referenceFileId}`);
      return;
    }

    // Находим данные для нужной колонки по её ID
    const columnData = reference.data.find(col => col.column_id === columnNameId);
    if (columnData) {
      const columnEntries = Object.entries(columnData.data);

      let filteredData;
      if (filterKey && filterColumn) {
        // Находим данные для колонки по filterColumn
        const filterColumnData = reference.data.find(col => col.column_id === filterColumn);
        if (filterColumnData) {
          // Фильтруем ключи в filterColumnData по filterKey
          const filterColumnEntries = Object.entries(filterColumnData.data);
          const filteredKeys = filterColumnEntries
            // eslint-disable-next-line
            .filter(([key, value]) => value.includes(filterKey))
            .map(([key]) => key);

          // Отфильтровываем columnEntries по отфильтрованным ключам
          filteredData = columnEntries
            // eslint-disable-next-line
            .filter(([key]) => filteredKeys.includes(key))
            // eslint-disable-next-line
            .map(([key, value]) => value);
        } else {
          console.error(`Filter column data not found for filterColumn ${filterColumn}`);
          filteredData = [];
        }
      } else {
        // Если filterKey не указан, используем все данные
        // eslint-disable-next-line
        filteredData = columnEntries.map(([key, value]) => value);
      }

      // Удаляем повторяющиеся значения и создаем массив объектов с уникальными значениями
      const uniqueValues = [...new Set(filteredData)];
      const options = uniqueValues.map(value => ({
        label: value,
        value: value,
      }));
      // Обновляем referenceOptions с уникальными данными
      referenceOptions.value[sku] = options;
    } else {
      console.error(`Column data not found for columnNameId ${columnNameId}`);
    }
  } catch (error) {
    console.error(`Error loading reference data for sku ${sku}:`, error);
  }
};




// Функция для оценки условий с учетом логического оператора внутри одного правила
const evaluateConditionsForRule = (conditions, operator) => {
  if (operator === 'AND') {
    return conditions.every(condition => {
      const answer = selectedAnswers.value[condition.question];
      const answerText = answer ? (Array.isArray(answer) ? answer.map(id => getAnswerTitle(condition.question, id)).join(', ') : getAnswerTitle(condition.question, answer)) : '';
      const answerNumber = parseFloat(answer);
      const conditionValueNumber = parseFloat(condition.value);
      if (condition.condition_type === 'SET') {
        // Сравниваем по answer_sku
        const result = answer && (Array.isArray(answer) ? answer.map(id => getAnswerSku(condition.question, id)).includes(condition.answer_sku) : getAnswerSku(condition.question, answer) === condition.answer_sku);
        return result;
      }
      if (condition.condition_type === 'CONTAINS') {
        const lowerAnswerText = answerText.toLowerCase();
        const lowerConditionValue = condition.value.toLowerCase();
        const result = lowerAnswerText.includes(lowerConditionValue);
        return result;
      }
      switch (condition.condition_type) {
        case 'EXISTS':
          // eslint-disable-next-line
          const exists = answer !== undefined && answer !== null;
          return exists;
        case 'LESS_THAN':
          if (!isNaN(answerNumber) && !isNaN(conditionValueNumber)) {
            const lessThan = answerNumber < conditionValueNumber;
            return lessThan;
          }
          return false;
        case 'GREATER_THAN':
          if (!isNaN(answerNumber) && !isNaN(conditionValueNumber)) {
            const greaterThan = answerNumber > conditionValueNumber;
            return greaterThan;
          }
          return false;
        case 'EQUALS':
          if (answerNumber === conditionValueNumber || answer === condition.value) {
            return true;
          }
          return false;
        case 'NOT_EQUALS':
          // eslint-disable-next-line
          const answerString = answer ? answer.toString() : ''; // Приведение к строке
          // eslint-disable-next-line
          const conditionString = condition.answer_sku ? condition.answer_sku.toString() : '';
          if (answerString !== conditionString) {
            return true;
          }
          return false;
        default:
          return false;
      }
    });
  } else if (operator === 'OR') {
    return conditions.some(condition => {
      const answer = selectedAnswers.value[condition.question];
      // const answerText = answer ? (Array.isArray(answer) ? answer.map(id => getAnswerTitle(condition.question, id)).join(', ') : getAnswerTitle(condition.question, answer)) : '';
      // Преобразуем значение и ответ в числа, если это возможно
      const answerNumber = parseFloat(answer);
      const conditionValueNumber = parseFloat(condition.value);
      if (condition.condition_type === 'SET') {
        const result = answer && (Array.isArray(answer) ? answer.map(id => getAnswerSku(condition.question, id)).includes(condition.answer_sku) : getAnswerSku(condition.question, answer) === condition.answer_sku);
        return result;
      }
      if (condition.condition_type === 'CONTAINS') {
        const result = answer.toLowerCase().includes(condition.value.toLowerCase());
        return result;
      }
      switch (condition.condition_type) {
        case 'EXISTS':
          // eslint-disable-next-line
          const exists = answer !== undefined && answer !== null;
          return exists;
        case 'LESS_THAN':
          if (!isNaN(answerNumber) && !isNaN(conditionValueNumber)) {
            const lessThan = answerNumber < conditionValueNumber;
            return lessThan;
          }
          return false;
        case 'GREATER_THAN':
          if (!isNaN(answerNumber) && !isNaN(conditionValueNumber)) {
            const greaterThan = answerNumber > conditionValueNumber;
            return greaterThan;
          }
          return false;
        case 'EQUALS':
          if (answerNumber === conditionValueNumber || answer === condition.value) {
            return true;
          }
          return false;
        case 'NOT_EQUALS':
          if (answerNumber !== conditionValueNumber || answer !== condition.value) {
            return true;
          }
          return false;
        default:
          return false;
      }
    });
  } else {
    return false;
  }
};
// Функция для получения заголовка ответа по его ID
const getAnswerTitle = (questionId, answerId) => {
  const question = screens.value.flatMap(screen => screen.elements)
    .find(element => element.element_type === 'QUESTION' && element.question.id === questionId);
  const answer = question ? question.question.answers.find(ans => ans.id === answerId) : null;
  return answer ? answer.title : '';
};
// Функция для получения answer_sku по questionId и answerId
const getAnswerSku = (questionId, answerId) => {
  const question = screens.value.flatMap(screen => screen.elements)
    .find(element => element.element_type === 'QUESTION' && element.question.id === questionId);
  const answer = question ? question.question.answers.find(ans => ans.id === answerId) : null;
  return answer ? answer.answer_sku : '';
};
// Функция фильтрации элементов на основе правил
const filterElementsByRules = (elements) => {
  return elements.filter(element => {
    if (element.element_type === 'QUESTION' || element.element_type === 'TEXT') {
      const rules = element.rules || [];
      // Проверяем правила для показа или скрытия
      const isElementVisible = rules.length === 0 || rules.some(rule => {
        if (rule.rule_type === 'SHOW' || rule.rule_type === 'HIDE') {
          const isConditionMet = evaluateConditionsForRule(rule.conditions, rule.operator);
          if (rule.rule_type === 'SHOW' && !isConditionMet) {
            return false; // Элемент не показывается
          }
          if (rule.rule_type === 'HIDE' && isConditionMet) {
            // Устанавливаем значение ответа для скрытого вопроса
            const questionId = element.question.id;
            if (rule.answer_to_set) {
              if (element.element_type === 'QUESTION' && element.question.question_type === 'MULTIPLE') {
                selectedAnswers.value[questionId] = [rule.answer_to_set];
              } else {
                selectedAnswers.value[questionId] = rule.answer_to_set;
              }
            }
            return false; // Элемент скрыт
          }
        }
        // Проверяем правила завершения опроса
        if (rule.rule_type === 'COMPLETE') {
          const isConditionMet = evaluateConditionsForRule(rule.conditions, rule.operator);
          if (isConditionMet) {
            shouldCompleteSurvey.value = true; // Устанавливаем флаг необходимости завершения
            completionText.value = rule.completion_text; // Устанавливаем текст завершения
          }
        }
        return true; // Оставляем элемент видимым, если ни одно правило не применимо
      });


      return isElementVisible;
    }
    return true; // Оставляем элементы без правил видимыми
  });
};
// Функция проверки заполненности полей
const validateScreen = () => {
  console.log("Starting validation")
  unansweredQuestions.value.clear();
  const elements = filteredElements.value;
  elements.forEach(element => {
    if (element.element_type === 'QUESTION') {
      const questionId = element.question.id;
      const questionType = element.question.question_type;
      // Проверка для вопросов типа SINGLE и REFERENCE
      if (questionType === 'SINGLE' || questionType === 'REFERENCE') {
        if (element.question.require_answers) {
          // Если вопрос не обязателен, пропускаем проверку
          return;
        }
        let isAnswered = true;

        if (questionType === 'REFERENCE') {
          element.question.answers.forEach(answer => {
            const answerKey = `${questionId}_${answer.id}`;
            if (!selectedAnswers.value[answerKey]) {
              isAnswered = false;
            }
          });
        } else {
          if (selectedAnswers.value[questionId] == null) {
            isAnswered = false;
          }
        }

        if (!isAnswered) {
          unansweredQuestions.value.add(questionId);
        } else {
          // Проверка текста открытого ответа только если ответ выбран и является открытым
          const isOpenAnswerSelected = element.question.answers.some(answer =>
            answer.is_open && answer.id === selectedAnswers.value[questionId]
          );
          if (isOpenAnswerSelected && !openAnswerText.value[questionId]) {
            unansweredQuestions.value.add(questionId);
          }
        }
      }
      // Проверка для вопросов типа MULTIPLE
      if (questionType === 'MULTIPLE') {
        const selectedAnswerIds = selectedAnswers.value[questionId] || [];
        if (selectedAnswerIds.length === 0) {
          unansweredQuestions.value.add(questionId);
        } else {
          // Проверка текста открытого ответа только для выбранных открытых ответов
          const openAnswers = element.question.answers.filter(answer =>
            answer.is_open && selectedAnswerIds.includes(answer.id)
          );
          if (openAnswers.length > 0 && !openAnswerText.value[questionId]) {
            unansweredQuestions.value.add(questionId);
          }
        }
      }
      // Проверка для вопросов типа NUM и SCALE
      if (questionType === 'NUM' || questionType === 'SCALE') {
        if (selectedAnswers.value[questionId] == null) {
          unansweredQuestions.value.add(questionId);
        } else {
          // Проверка текста открытого ответа только если выбран открытый ответ
          const isOpenAnswerSelected = element.question.answers.some(answer =>
            answer.is_open && answer.id === selectedAnswers.value[questionId]
          );
          if (isOpenAnswerSelected && !openAnswerText.value[questionId]) {
            unansweredQuestions.value.add(questionId);
          }
        }
      }
      // Проверка для вопросов типа GRID
      if (questionType === 'GRID' && element.question.grid_type != 'CUSTOM') {
        let isValid = true;
        // Проверяем каждую строку
        element.question.child_questions.forEach(rowQuestion => {
          const selectedAnswersForRow = selectedAnswers.value[rowQuestion.id] || [];
          if (selectedAnswersForRow.length === 0) {
            isValid = false;
            unansweredQuestions.value.add(rowQuestion.id);
          }
        });
        // Проверяем, если в любом ряду нет ответа, добавляем родительский вопрос в непроходящие вопросы
        if (!isValid) {
          unansweredQuestions.value.add(questionId);
        }
      }
      // Специфическая проверка для вопроса с id = 225
      if (questionId === 225) {
        let isValid = true;

        // Получаем все индексы рядов, где dropdownOne заполнен
        const filledRowIndices = Object.keys(dropdownValues.value)
          .filter(key => key.startsWith('dropdownOne_'))
          .map(key => parseInt(key.split('_')[1]))
          .filter(rowIndex => dropdownValues.value[`dropdownOne_${rowIndex}`]);

        // Если нет заполненных dropdownOne, добавляем вопрос в непроходящие
        if (filledRowIndices.length === 0) {
          unansweredQuestions.value.add(questionId);
        } else {
          // Для всех найденных rowIndex проверяем правильность данных
          filledRowIndices.forEach(rowIndex => {
            const startRow = Math.floor(rowIndex / 9) * 9;
            const endRow = startRow + 9;

            for (let i = startRow; i < endRow; i++) {
              const rowQuestionId = `225_${i + 1}`;
              const rowQuestion = element.question.child_questions.find(q => q.id === rowQuestionId);

              if (rowQuestion) {
                // Проверяем ответы для текущего ряда
                element.question.answers.forEach(answer => {
                  if (answer.id !== 459) {  // Пропускаем проверку для ответа с id = 459
                    const answerKey = `${rowQuestionId}_${answer.id}`;
                    if (!selectedAnswers.value[answerKey]) {
                      isValid = false;
                      unansweredQuestions.value.add(rowQuestionId);
                    }
                  }
                });

                if (!isValid) {
                  unansweredQuestions.value.add(questionId);
                }
              }
            }
          });
        }
      }
      // Логика для GRID с динамическими рядами
      if (questionType === 'GRID' && element.question.grid_type === 'CUSTOM' && element.question.has_dynamic_rows && questionId != 225) {
        if (element.question.not_required) {
          // Если вопрос не обязателен, пропускаем проверку
          return;
        }

        let hasFilledDropdown = false; // Флаг для проверки наличия заполненных дропдаунов
        let isValid = true;

        // Сначала проверяем наличие хотя бы одного заполненного дропдауна
        element.question.child_questions.forEach(rowQuestion => {
          const selectedDropdownValue = selectedAnswers.value[rowQuestion.id];
          if (selectedDropdownValue) {
            hasFilledDropdown = true; // Если найден заполненный дропдаун, устанавливаем флаг в true
          }
        });

        if (!hasFilledDropdown) {
          // Если нет заполненных дропдаунов, добавляем ошибку к первому дропдауну
          unansweredQuestions.value.add(element.question.child_questions[0].id);
          return; // Выходим из проверки
        }

        // Проверяем остальные поля, если есть заполненные дропдауны
        element.question.child_questions.forEach(rowQuestion => {
          const selectedDropdownValue = selectedAnswers.value[rowQuestion.id];
          if (!selectedDropdownValue) {
            return; // Если дропдаун не заполнен, пропускаем проверку этой строки
          }

          // Проверяем другие поля в строке
          element.question.answers.forEach(answer => {
            const answerKey = `${rowQuestion.id}_${answer.id}`;
            if (!selectedAnswers.value[answerKey]) {
              isValid = false;
              unansweredQuestions.value.add(rowQuestion.id);
            }
          });
        });

        if (!isValid) {
          unansweredQuestions.value.add(questionId);
        }
      }

    }
  });
  return unansweredQuestions.value.size === 0;
};

const isQuestionAnswered = (questionId) => {
  return !unansweredQuestions.value.has(questionId);
};
const updateOpenAnswerText = (questionId, value) => {
  openAnswerText.value = { ...openAnswerText.value, [questionId]: value };
};

const isScreenVisible = (screen) => {
  // Фильтруем элементы экрана, чтобы определить, есть ли видимые элементы
  const visibleElements = filterElementsByRules(screen.elements);
  return visibleElements.length > 0;
};

const nextScreen = () => {
  if (validateScreen()) {
    let currentScreen = screens.value[currentScreenIndex.value];
    if (shouldCompleteSurvey.value) {
      isSurveyCompleted.value = true; // Завершаем опрос
      shouldCompleteSurvey.value = false; // Сбрасываем флаг завершения
      if (studentId != 1) {
        saveSurveyData({
          studentId,
          surveyId: surveyId.value,
          responses: allOfThem.value,
          currentScreenId: currentScreen.id,
          isSurveyCompleted: true
        })
          .then(response => {
            console.log('Data successfully saved', response);
          })
          .catch(error => {
            console.error('Error saving data:', error);
          });
      }
    }

    else {

      if (currentScreenIndex.value < screens.value.length - 1) {
        currentScreenIndex.value += 1;
        let newCurrentScreen = screens.value[currentScreenIndex.value];
        if (studentId != 1) {
          saveSurveyData({
            studentId,
            surveyId: surveyId.value,
            responses: allOfThem.value,
            currentScreenId: newCurrentScreen.id,
            isSurveyCompleted: false
          })
            .then(response => {
              console.log('Data successfully saved:', response);
            })
            .catch(error => {
              console.error('Error saving data:', error);
            });
        }

        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });

        updateDropdownValues(321, 322);

        screens.value[currentScreenIndex.value].elements.forEach(element => {
          if (element.element_type === 'TEXT') {
            element.content = replaceQuestionSkusInText(element.content);
          }
          if (element.element_type === 'QUESTION') {
            element.question.title = replaceQuestionTitlesInText(element.question.title);
          }
        });

        // Скрываем если нет элементов
        if (!isScreenVisible(screens.value[currentScreenIndex.value])) {
          nextScreen()
        }
      }

    }

  }
};
const finishSurvey = () => {
  if (validateScreen()) {
    if (studentId != 1) {
      saveSurveyData({
        studentId,
        surveyId: surveyId.value,
        responses: allOfThem.value,
        currentScreenId: null, // Нет текущего экрана, так как опрос завершён
        isSurveyCompleted: true // Опрос завершён
      })
        .then(response => {
          console.log('Survey successfully completed:', response);
        })
        .catch(error => {
          console.error('Error completing survey:', error);
        });
    }
    window.parent.postMessage({
      action: 'next',
    }, '*');
  }
};

const allQuestionsAndAnswers = computed(() => {
  if (!isDataLoaded.value) {
    return []; // Не возвращаем данные, пока они не загружены
  }
  const allQuestions = [];
  screens.value.forEach(screen => {
    screen.elements.forEach(element => {
      if (element.element_type === 'QUESTION') {
        const questionId = element.question.id;
        const questionTitle = element.question.title;
        const questionSku = element.question.question_sku;
        const questionType = element.question.question_type;
        let answerText = 'NULL';
        let answerId = 'NULL';

        // Обрабатываем ответы для основного вопроса
        if (questionType === 'SINGLE') {
          answerId = selectedAnswers.value[questionId] || 'NULL';
          const selectedAnswer = element.question.answers.find(ans => ans.id === answerId);
          answerText = selectedAnswer ? selectedAnswer.title : 'NULL';
          if (selectedAnswer && selectedAnswer.is_open) {
            answerText = openAnswerText.value[questionId] || answerText;
          }
        } else if (questionType === 'MULTIPLE') {
          const selectedAnswersForQuestion = selectedAnswers.value[questionId];
          let selectedAnswerIds = Array.isArray(selectedAnswersForQuestion)
            ? selectedAnswersForQuestion
            : (typeof selectedAnswersForQuestion === 'number'
              ? [selectedAnswersForQuestion]
              : []);
          answerId = selectedAnswerIds.length > 0 ? selectedAnswerIds.join(', ') : 'NULL';
          answerText = selectedAnswerIds.map(ansId => {
            const selectedAnswer = element.question.answers.find(ans => ans.id === ansId);
            if (selectedAnswer && selectedAnswer.is_open) {
              return openAnswerText.value[questionId] || selectedAnswer.title;
            }
            return selectedAnswer ? selectedAnswer.title : 'NULL';
          }).join(', ');
        } else if (questionType === 'NUM' || questionType === 'SCALE') {
          const isNoneSelected = element.question.answers.some(answer =>
            answer.is_none && selectedAnswers.value[questionId] === 0 // не знаю сработает ли
          );
          answerId = isNoneSelected ? 'NONE' : 'numeric';
          answerText = isNoneSelected ? element.question.answers.find(answer => answer.is_none).title : (selectedAnswers.value[questionId] != null ? selectedAnswers.value[questionId] : 'NULL');
        } else if (questionType === 'REFERENCE') {
          // Проверяем, если выбран вариант с `is_none`
          const isNoneSelectedForQuestion = element.question.answers.some(answer => answer.is_none && isNoneSelected(questionId));

          if (isNoneSelectedForQuestion) {
            // Если выбран вариант с `is_none`, находим его и устанавливаем его `answerText`
            const noneAnswer = element.question.answers.find(answer => answer.is_none);
            answerId = 'NONE'; // Или другой подходящий идентификатор
            answerText = noneAnswer ? noneAnswer.title : 'NULL';
          } else {
            // Если `is_none` не выбран, обрабатываем стандартный список ответов
            const selectedAnswerIds = element.question.answers.map(answer => {
              return selectedAnswers.value[`${questionId}_${answer.id}`] || 'NULL';
            }).join(', ');

            const selectedAnswerTexts = element.question.answers.map(answer => {
              const selectedValue = selectedAnswers.value[`${questionId}_${answer.id}`] || 'NULL';
              return selectedValue;
            }).join(', ');

            answerId = selectedAnswerIds;
            answerText = selectedAnswerTexts;
          }
        }

        const questionData = {
          questionId,
          questionSku,
          questionTitle,
          answerId,
          answerText
        };

        if (questionType === 'GRID') {
          questionData.childQuestions = [];
          const childQuestions = element.question.child_questions || []; // Получаем дочерние вопросы

          childQuestions.forEach(childQuestion => {
            const childQuestionId = childQuestion.id;
            // Проверяем, есть ли выбранное значение для дочернего вопроса
            let childQuestionTitle = childQuestion.title;
            if (element.question.has_dynamic_rows) {
              if (selectedAnswers.value[childQuestionId]) {
                console.log("YES", selectedAnswers.value[childQuestionId])
                childQuestionTitle = selectedAnswers.value[childQuestionId]
              }
            }
            const childQuestionType = childQuestion.question_type;

            let childAnswerText = [];
            let childAnswerId = 'NULL';


            if (element.question.grid_type === 'CUSTOM' && element.question.answers.length > 0) {
              element.question.answers.forEach(column => {
                const columnAnswerId = selectedAnswers.value[`${childQuestionId}_${column.id}`] || 'NULL';
                const columnAnswerText = selectedAnswers.value[`${childQuestionId}_${column.id}`] || 'NULL';
                /* let columnAnswerText = columnAnswerId !== 'NULL'
                  ? (column.title || 'NULL')
                  : 'NULL';
                */
                console.log("We are here")
                childAnswerText.push({
                  columnId: column.id,
                  columnTitle: column.title,
                  answerId: columnAnswerId,
                  answerText: columnAnswerText
                });
              });
            } else {
              if (element.question.grid_type === 'SCALE') {
                console.log("CHILD!", childQuestionType)
                childAnswerId = selectedAnswers.value[childQuestionId] || 'NULL';
                childAnswerText = childAnswerId !== 'NULL' ? childAnswerId : 'NULL';
              } else if (element.question.grid_type === 'MULTIPLE') {
                childAnswerText = Array.isArray(selectedAnswers.value[childQuestionId])
                  ? selectedAnswers.value[childQuestionId].map(ansId => {
                    const selectedAnswer = element.question.answers.find(ans => ans.id === ansId);
                    return selectedAnswer ? selectedAnswer.title : 'NULL';
                  }).join(', ')
                  : 'NULL';
              } else {
                childAnswerId = selectedAnswers.value[childQuestionId] || 'NULL';
                childAnswerText = childAnswerId !== 'NULL'
                  ? (element.question.answers.find(ans => ans.id === childAnswerId)?.title || 'NULL')
                  : 'NULL';
              }
            }
            questionData.childQuestions.push({
              questionId: childQuestionId,
              questionTitle: childQuestionTitle,
              answerId: childAnswerId,
              answerText: childAnswerText
            });
          });
        }
        allQuestions.push(questionData);
      }
    });
  });
  return allQuestions;
});


const handleNoneAnswerChange = (questionId, noneAnswerId) => {
  const questionType = getQuestionType(questionId);
  console.log("questionType", questionType)
  if (questionType === 'SINGLE') {
    if (selectedAnswers.value[questionId] === noneAnswerId) {
      // Если выбран is_none вариант, то сбрасываем выбор
      selectedAnswers.value[questionId] = null;
    } else {
      // Если is_none снят, то устанавливаем его выбор
      selectedAnswers.value[questionId] = noneAnswerId;
    }
  } else if (questionType === 'REFERENCE') {
    if (selectedAnswers.value[questionId] === noneAnswerId) {
      // Если выбран is_none вариант, то сбрасываем выбор
      console.log("REFERENCE IS SET", noneAnswerId)
      selectedAnswers.value[questionId] = null;

    } else {
      // Если is_none снят, то устанавливаем его выбор
      console.log("REFERENCE IS OUT", noneAnswerId)
      selectedAnswers.value[questionId] = noneAnswerId;
      for (const key in selectedAnswers.value) {
        console.log("ID", key)
        if (key.startsWith(`${questionId}_`) && key !== `${questionId}_${noneAnswerId}`) {
          console.log("Hello ID", key)
          selectedAnswers.value[key] = null;
        }
      }
    }
  } else if (questionType === 'MULTIPLE') {
    console.log("handleNoneAnswerChange MULTIPLE")
    if (selectedAnswers.value[questionId]?.includes(noneAnswerId)) {
      console.log("We have", noneAnswerId)
      // Если выбран is_none вариант, то снимаем выбор всех других вариантов
      selectedAnswers.value[questionId] = [noneAnswerId];
    } else {
      // Если is_none снят, то очищаем его выбор
      selectedAnswers.value[questionId] = selectedAnswers.value[questionId].filter(id => id !== noneAnswerId);
    }
  } else if (questionType === 'GRID') {
    console.log("handleNoneAnswerChange GRID")
    if (selectedAnswers.value[questionId]?.includes(noneAnswerId)) {
      console.log("We have", noneAnswerId)
      // Если выбран is_none вариант, то снимаем выбор всех других вариантов
      selectedAnswers.value[questionId] = [noneAnswerId];
    } else {
      // Если is_none снят, то очищаем его выбор
      selectedAnswers.value[questionId] = selectedAnswers.value[questionId].filter(id => id !== noneAnswerId);
    }
  }
};

const isNoneSelected = (questionId) => {
  const questionType = getQuestionType(questionId);
  if (questionType === 'SINGLE') {
    return selectedAnswers.value[questionId] && isNoneAnswer(questionId, selectedAnswers.value[questionId]);
  } else if (questionType === 'REFERENCE') {
    return selectedAnswers.value[questionId] && isNoneAnswer(questionId, selectedAnswers.value[questionId]);
  } else if (questionType === 'MULTIPLE') {
    const answers = Array.isArray(selectedAnswers.value[questionId]) ? selectedAnswers.value[questionId] : [];
    return answers.some(answerId => isNoneAnswer(questionId, answerId));
  } else if (questionType === 'GRID') {
    console.log("Checking isNoneSelected GRID", questionId)
    // Получаем ответы для каждой строки (дочернего вопроса) грида
    const rowAnswers = Array.isArray(selectedAnswers.value[questionId]) ? selectedAnswers.value[questionId] : [];
    console.log("rowAnswers", rowAnswers)
    return rowAnswers.some(answerId => isNoneAnswer(questionId, answerId));
  }
  return false;
};

const getQuestionType = (questionId) => {
  console.log("questionId", questionId);

  // Сначала ищем основной вопрос
  const question = screens.value.flatMap(screen => screen.elements)
    .find(element => element.element_type === 'QUESTION' && element.question.id === questionId);

  if (question) {
    return question.question.question_type;
  } else {
    // Если основной вопрос не найден, ищем среди дочерних вопросов
    const childQuestion = screens.value.flatMap(screen => screen.elements)
      .flatMap(element => element.element_type === 'QUESTION' ? element.question.child_questions : [])
      .find(child => child && child.id === questionId); // Проверка на null

    return childQuestion ? childQuestion.question_type : null;
  }
};

const isNoneAnswer = (questionId, answerId) => {
  console.log("isNoneAnswer", questionId, answerId);

  // Сначала ищем основной вопрос
  const question = screens.value.flatMap(screen => screen.elements)
    .find(element => element.element_type === 'QUESTION' && element.question?.id === questionId);

  // Если нашли основной вопрос, проверяем его ответы
  if (question) {
    return question.question.answers?.some(answer => answer.id === answerId && answer.is_none) || false;
  } else {
    // Если основного вопроса нет, ищем среди дочерних вопросов
    const childQuestion = screens.value.flatMap(screen => screen.elements)
      .flatMap(element => element.element_type === 'QUESTION' ? element.question.child_questions || [] : [])
      .find(child => child.id === questionId);

    if (childQuestion) {
      // Найти родительский вопрос, к которому относится этот дочерний вопрос
      const parentQuestion = screens.value.flatMap(screen => screen.elements)
        .find(element => element.element_type === 'QUESTION' && element.question.child_questions?.some(child => child.id === questionId));

      console.log("parentQuestion", parentQuestion);

      // Если нашли родительский вопрос, проверяем его ответы
      return parentQuestion?.question.answers?.some(answer => answer.id === answerId && answer.is_none) || false;
    } else {
      return false;
    }
  }
};



const formattedRules = computed(() => {
  const allRules = [];
  screens.value.forEach(screen => {
    screen.elements.forEach(element => {
      if (element.element_type === 'QUESTION') {
        const questionId = element.question.id;
        const questionRules = element.rules || [];
        questionRules.forEach(rule => {
          allRules.push({
            questionId,
            ruleType: rule.rule_type,
            condition: rule.conditions.map(cond => ({
              question: cond.question,
              type: cond.condition_type,
              answer: cond.answer_sku || cond.value
            }))
          });
        });
      }
    });
  });
  return allRules;
});

onMounted(fetchSurveyData);

const shuffleArray = (array) => {
  let currentIndex = array.length, randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
};
const shuffledAnswers = (answers) => {
  const fixedAnswers = answers.filter(answer => answer.is_rotatable);
  const nonFixedAnswers = answers.filter(answer => !answer.is_rotatable);
  const shuffledNonFixedAnswers = shuffleArray(nonFixedAnswers);
  const finalAnswers = [];
  let fixedIndex = 0, nonFixedIndex = 0;
  for (let i = 0; i < answers.length; i++) {
    if (fixedAnswers.some(answer => answer.order === i + 1)) {
      finalAnswers.push(fixedAnswers[fixedIndex++]);
    } else {
      finalAnswers.push(shuffledNonFixedAnswers[nonFixedIndex++]);
    }
  }
  return finalAnswers;
};

// Функция для перемешивания дочерних вопросов с учетом их rotatable свойства
const shuffleChildQuestions = (childQuestions) => {
  const fixedChildQuestions = childQuestions.filter(question => question.is_rotatable);
  const rotatableChildQuestions = childQuestions.filter(question => !question.is_rotatable);
  const shuffledRotatableChildQuestions = shuffleArray(rotatableChildQuestions);
  const finalChildQuestions = [];
  let fixedIndex = 0, shuffledIndex = 0;
  childQuestions.forEach((question) => {
    if (!question.is_rotatable) {
      finalChildQuestions.push(shuffledRotatableChildQuestions[shuffledIndex++]);
    } else {
      finalChildQuestions.push(fixedChildQuestions[fixedIndex++]);
    }
  });
  return finalChildQuestions;
};

const hiddenQuestions = computed(() => {
  if (!currentScreen.value) return [];
  const hiddenQuestions = [];
  const elements = filterElementsByRules(currentScreen.value.elements);
  currentScreen.value.elements.forEach(element => {
    if (element.element_type === 'QUESTION') {
      const questionId = element.question.id;
      const isVisible = elements.includes(element);
      if (!isVisible) {
        hiddenQuestions.push({
          questionId,
          questionTitle: element.question.title,
        });
      }
    }
  });
  return hiddenQuestions;
});

watch(hiddenQuestions, (newHiddenQuestions, oldHiddenQuestions) => {
  const newlyVisibleQuestions = oldHiddenQuestions.filter(oldQ => !newHiddenQuestions.some(newQ => newQ.questionId === oldQ.questionId));

  // Сбрасываем значения для вновь показанных вопросов
  newlyVisibleQuestions.forEach(question => {
    selectedAnswers.value[question.questionId] = "";
  });
});

const sortByOrder = (answers) => {
  return answers.slice().sort((a, b) => (a.order || 0) - (b.order || 0));
};

// Dynamic rows
const showMoreRows = (question) => {
  // HARDCODE Q-33
  if (question.id === 225) {
    // Добавляем 9 рядов или оставшиеся строки до конца массива child_questions
    question.visibleRows = Math.min(question.visibleRows + 9, question.child_questions.length);
    // HARDCODE Q-33
  } else {
    question.visibleRows = Math.min(question.visibleRows + 1, question.child_questions.length);
  }
};

// Replace dynamic text
const replaceQuestionSkusInText = (content) => {
  const skuRegex = /\[Q-(\d+)\]/g;

  return content.replace(skuRegex, (match, sku) => {
    // Найдите вопрос по question_sku
    const question = allQuestionsAndAnswers.value.find(q => q.questionSku === `Q-${sku}`);
    return question ? question.answerText : match;
  });
};
const replaceQuestionTitlesInText = (text) => {
  const skuRegex = /\[Q-(\d+)\]/g;

  return text.replace(skuRegex, (match, sku) => {
    // Найдите вопрос по question_sku
    const question = allQuestionsAndAnswers.value.find(q => q.questionSku === `Q-${sku}`);
    return question ? question.answerText : match;
  });
};

watch([isDataLoaded, allQuestionsAndAnswers], ([newIsDataLoaded, newAllQuestionsAndAnswers]) => {
  if (newIsDataLoaded) {
    // Формируем данные для сохранения
    allOfThem.value = newAllQuestionsAndAnswers.map(question => {
      // Формируем структуру ответа
      let childQuestions = [];
      if (question.childQuestions && question.childQuestions.length > 0) {
        childQuestions = question.childQuestions.map(childQuestion => {
          // Формируем ответы по колонкам для кастомных грида
          let columnAnswers = [];
          if (Array.isArray(childQuestion.answerText)) {
            columnAnswers = childQuestion.answerText.map(columnAnswer => ({
              columnId: columnAnswer.columnId,
              columnTitle: columnAnswer.columnTitle,
              answerId: columnAnswer.answerId,
              answerText: columnAnswer.answerText
            }));
          }
          return {
            questionId: childQuestion.questionId,
            questionTitle: childQuestion.questionTitle,
            answerId: childQuestion.answerId,
            answerText: childQuestion.answerText,
            columnAnswers: columnAnswers // Добавляем данные по колонкам
          };
        });
      }
      return {
        questionId: question.questionId,
        questionTitle: question.questionTitle,
        answerId: question.answerId,
        answerText: question.answerText,
        childQuestions: childQuestions
      };
    });

  }
});

const filterReferenceDataByKey = async (questionFilterId, answerId) => {
  // Пройдемся по всем экранам
  for (const screen of screens.value) {
    for (const element of screen.elements) {
      if (element.element_type === 'QUESTION' && element.question.question_type === 'REFERENCE') {
        for (const answer of element.question.answers) {
          if (answer.filter_answer_reference_id && answer.filter_answer_reference_id === answerId) {
            const referenceFileId = answer.reference_file;
            const columnName = answer.reference_column;
            const filter_answer_reference_id = answer.filter_answer_reference_id;
            if (referenceFileId && columnName && filter_answer_reference_id) {
              // Найти question.id по filter_answer_reference_id в screens.value
              let questionId;
              let filterColumn;
              for (const screenElement of screen.elements) {
                if (screenElement.element_type === 'QUESTION' &&
                  screenElement.question.question_type === 'REFERENCE') {
                  const matchingAnswer = screenElement.question.answers.find(ans => ans.id === filter_answer_reference_id);
                  if (matchingAnswer) {
                    questionId = screenElement.question.id;
                    filterColumn = matchingAnswer.reference_column
                    break;
                  }
                }
              }
              if (questionId) {
                // Найти значение answerText для questionId в allQuestionsAndAnswers
                const matchingQuestion = allQuestionsAndAnswers.value.find(question =>
                  question.questionId === questionId
                );
                if (matchingQuestion) {
                  const filterKey = selectedAnswers.value[questionFilterId + '_' + answerId];
                  // Запускаем loadReferenceData для фильтрации с использованием filterKey
                  await loadReferenceData(`${element.question.id}_${answer.id}`, referenceFileId, columnName, filterColumn, filterKey);
                }
              }
            }

          }
        }
      }
    }
  }
};

const filterReferenceDynamicDataByKey = async (questionFilterId, answerId) => {
  // Пройдемся по всем экранам
  for (const screen of screens.value) {
    for (const element of screen.elements) {
      if (element.element_type === 'QUESTION' && element.question.question_type === 'GRID' && element.question.id === questionFilterId) {
        // нашли вопрос в элементах, в котором поменялся дропдаун дочернего динамического ряда в элементах
        for (const answer of element.question.answers) {
          if (answer.filter_answer_reference_id && answer.filter_answer_reference_id === answer.id) {
            // нашли ответ в элементах в котором есть информация о дропдауне
            const filterKey = selectedAnswers.value[answerId]
            // переменные справочников динамического ряда
            const rowColumnName = element.question.reference_column;
            // переменные справочников колонки
            const columnReferenceFileId = answer.reference_file;
            const columnColumnName = answer.reference_column;
            if (columnReferenceFileId && columnColumnName) {
              await loadReferenceData(`${element.question.id}_${answerId}_${answer.id}`, columnReferenceFileId, columnColumnName, rowColumnName, filterKey);
            }
          }
        }

      }
    }
  }
};

const filterReferenceDataByColumn = async (questionFilterId, rowQuestionId, answerId) => {
  // Пройдемся по всем экранам
  console.log("Wow", questionFilterId, answerId)
  for (const screen of screens.value) {
    for (const element of screen.elements) {
      if (element.element_type === 'QUESTION' && element.question.question_type === 'GRID' && element.question.id === questionFilterId) {
        // нашли вопрос в элементах, в котором поменялся дропдаун дочернего динамического ряда в элементах
        // нам не нуэно искать ответы и продолжать, если у answerId НЕТ filter_answer_reference_id и 
        for (const answer of element.question.answers) {
          if (answer.filter_answer_reference_id && answer.filter_answer_reference_id != answer.id && answer.filter_answer_reference_id === answerId) {
            console.log("Answer ID", answer)
            // нашли ответ в элементах в котором есть информация о дропдауне
            const filterKey = selectedAnswers.value[rowQuestionId + '_' + answerId]
            console.log("Row Value is", filterKey)
            // переменные справочников динамического ряда
            const rowReferenceFileId = element.question.reference_file;
            const rowColumnName = element.question.reference_column;
            // переменные справочников колонки в которой нужно менять ответ
            const columnReferenceFileId = answer.reference_file;
            const columnColumnName = answer.reference_column;

            console.log(rowReferenceFileId, rowColumnName, columnReferenceFileId, columnColumnName)
            console.log("loading DATA to", `${element.question.id}_${rowQuestionId}_${answer.id}`)
            if (columnReferenceFileId && columnColumnName) {
              await loadReferenceData(`${element.question.id}_${rowQuestionId}_${answer.id}`, columnReferenceFileId, columnColumnName, rowColumnName, filterKey);
            }
          }
        }

      }
    }
  }
};

// HARDCODE Q-33
const dropdownValues = ref({}); // Содержит значения для всех дропдаунов
const disabledDropdowns = ref({});

const handleFirstDropdownChange = async (rowIndex, rowQuestionId) => {
  console.log("rowQuestionId", rowQuestionId);
  // Получаем уникальные значения для первого дропдауна
  const uniqueDropdownOneValues = [
    ...new Set(
      Object.entries(dropdownValues.value)
        .filter(([key]) => key.startsWith('dropdownOne_')) // фильтруем ключи
        .map(([, value]) => value) // берем только значения
    ),
  ];
  console.log("uniqueDropdownOneValues", uniqueDropdownOneValues);

  // Обновляем ответы с уникальными значениями для dropdownOne
  updateSelectedAnswersNext(uniqueDropdownOneValues, '235', 1);
  updateSelectedAnswersNext(uniqueDropdownOneValues, '236', 1);

  const filterKey = dropdownValues.value['dropdownOne_' + rowIndex];
  console.log("filterKey", filterKey);
  const referenceKey = 88880 + rowIndex;
  if (filterKey) {
    console.log("filterKey one more", filterKey);
    // Обновите данные для второго дропдауна
    await loadReferenceData(referenceKey, 4, 19, 17, filterKey);

    updateSelectedAnswers(rowIndex, 458, filterKey);
  } else {
    // Очистите данные второго дропдауна, если нет фильтра
    referenceOptions.value[referenceKey] = [];
  }
};

const handleSecondDropdownChange = (rowIndex, rowQuestionId) => {
  const filterKey = dropdownValues.value['dropdownTwo_' + rowIndex];
  console.log("rowQuestionId", rowQuestionId);

  // Получаем уникальные значения для второго дропдауна
  const uniqueDropdownTwoValues = [
    ...new Set(
      Object.entries(dropdownValues.value)
        .filter(([key]) => key.startsWith('dropdownTwo_')) // фильтруем ключи
        .map(([, value]) => value) // берем только значения
    ),
  ];
  console.log("uniqueDropdownTwoValues", uniqueDropdownTwoValues);

  // Обновляем ответы с уникальными значениями для dropdownTwo
  updateSelectedAnswersNext(uniqueDropdownTwoValues, '236', 2);
  updateSelectedAnswers(rowIndex, 459, filterKey);
};

const updateSelectedAnswers = (rowIndex, answerId, newValue) => {
  const startRow = Math.floor(rowIndex / 9) * 9 + 1;
  const endRow = startRow + 9;
  // Обновите selectedAnswers для всех строк в указанном диапазоне
  for (let i = startRow; i < endRow; i++) {
    const key = `225_${i}_${answerId}`;
    selectedAnswers.value[key] = newValue;
  }
};
// Обновленная функция для проверки пар дропдаунов
const updateSelectedAnswersNext = (uniqueValues, prefix, dropdownNumber) => {
  // Собираем пары значений для дропдаунов
  const dropdownPairs = new Set();

  uniqueValues.forEach((value, index) => {
    let key = ref('');
    if (dropdownNumber === 1) {
      key.value = `${prefix}_${index + 1}`;
    } else {
      key.value = `${prefix}_${index + 1}_383`;
    }

    // Получаем значения первой и второй пары дропдаунов для текущего индекса
    const dropdownOneValue = dropdownValues.value[`dropdownOne_${index}`];
    const dropdownTwoValue = dropdownValues.value[`dropdownTwo_${index}`];

    // Создаем строку для сравнения пар
    const pairKey = `${dropdownOneValue}_${dropdownTwoValue}`;

    // Проверяем, существует ли уже такая пара
    if (!dropdownPairs.has(pairKey)) {
      // Если пара уникальна, добавляем её в Set и обновляем selectedAnswers и disabledDropdowns
      dropdownPairs.add(pairKey);
      console.log(`Updating selectedAnswers for ${key.value} with value:`, value);
      console.log("selectedAnswers.value[key]", key.value, selectedAnswers.value[key.value]);
      selectedAnswers.value[key.value] = value;
      console.log("SET selectedAnswers.value[key]", selectedAnswers.value[key.value]);
      disabledDropdowns.value[key.value] = value;
    } else {
      console.log(`Duplicate pair found: ${pairKey}, skipping update.`);
    }
  });
};
// HARDCODE Q-33

const updateDropdownValues = (sourceId, targetId) => {
  // Найти значение ответа из источника
  const sourceAnswer = allQuestionsAndAnswers.value.find(answer => answer.questionId === sourceId);
  const sourceValue = sourceAnswer ? sourceAnswer.answerText : '';

  // Проверяем, что текущий экран существует
  if (!screens.value[currentScreenIndex.value]) {
    console.error("Current screen not found.");
    return;
  }

  // Найти элемент с целевым ID
  const targetElement = screens.value[currentScreenIndex.value].elements.find(element => element.question && element.question.id === targetId);

  if (targetElement && targetElement.element_type === 'QUESTION' && targetElement.question.question_type === 'GRID') {
    const firstDropdown = targetElement.question.child_questions && targetElement.question.child_questions[0];

    if (firstDropdown && firstDropdown.id) {
      // Установить значение в дропдаун
      selectedAnswers.value[firstDropdown.id] = sourceValue;
    } else {
      console.warn("First dropdown not found or has no ID.");
    }
  } else {
    console.warn("Target element not found, or not a QUESTION with GRID type.");
  }
};



</script>

<style scoped>
h4 {
  font-weight: 500;
  font-size:17px;
}

.survey-page {
  padding: 20px;
}

.screen-content {
  margin-bottom: 20px;
}

.screen-element {
  margin-bottom: 15px;
}

.question-error,
.highlight-red {
  color: red;
}

.grid-table {
  border: 1px solid #cbd5e1;
  border-radius: 8px;
}

.grid-table tr td,
.grid-table tr th {
  padding: 15px;
  max-width: 280px;
  text-align: center;
}

/* Кружочки с цифрами для рейтинга */
.rating-circle {
  display: inline-block;
  width: 30px;
  /* Размер кружочка */
  height: 30px;
  border-radius: 50%;
  background-color: #2196f3;
  /* Основной цвет кружочка */
  color: #fff;
  /* Цвет текста внутри кружочка */
  text-align: center;
  line-height: 30px;
  /* Выравнивание текста по центру */
  font-size: 14px;
  /* Размер шрифта для цифры */
  margin: 2px;
  /* Расстояние между кружочками */
}

.rating-circle.active {
  background-color: #1565c0;
  /* Цвет активного кружочка */
}
</style>
<style>
.num-block .p-inputnumber-input,
.grid-table .p-inputnumber-input {
  width: 65px;
}


.table-container {
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  /* для плавного скролла на iOS */
}

.grid-table {
  border-collapse: collapse;
}

.grid-table thead th:first-child,
.grid-table tbody td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background: white;
  min-width: 150px;
  text-align: left;
}

table .highlight-red>.p-select {

  border-color: red;

}

#public table .p-select {
  width: 100% !important;

}
</style>