<template>
<div class="rules-list">
    <p v-if="rules.length === 0" class="sub-text">Пока правил здесь нет</p>
    
    <div v-else>
        <div class="rules">
            <div class="rule" v-for="rule in rules" :key="rule.id">
                <span v-if="rule.rule_type == 'SHOW'"><b>Показывать</b> текущий вопрос, если:</span>
                <span v-if="rule.rule_type == 'HIDE'"><b>Скрывать</b> текущий вопрос, если:</span>
                <span v-if="rule.rule_type == 'COMPLETE'"><b>Завершать</b> опрос, если:</span>   
                <div class="cond" v-for="(cond, condIndex) in formatConditions(rule.conditions)" :key="cond.question">
                    <p>
                        <span v-if="cond.question_detail"> в вопросе <b class="mr-1">{{ cond.question_detail.question_sku }} </b> </span>
                        <span v-if="cond.conditionType == 'SET'"><b>выбран </b></span> 
                        <span>ответ <b class="mr-1">{{ cond.answerSku }}</b></span>
                        <span v-if="cond.conditionType == 'EXISTS'"><b>существует</b></span>
                        <span v-if="cond.conditionType == 'CONTAINS'"><b class="mr-1">содержит</b> текст</span>
                        <span v-if="cond.conditionType == 'EQUALS'"><b class="mr-1">равен</b></span>
                        <span v-if="cond.conditionType == 'LESS_THAN'"><b class="mr-1">больше чем</b></span>
                        <span v-if="cond.conditionType == 'GREATER_THAN'"><b class="mr-1">меньше чем</b></span>
                        <span v-if="cond.conditionType == 'NOT_EQUALS'"><b class="mr-1">не равен</b></span>
                        <span><b>{{ cond.value }}</b></span>
                        </p>
                        <!-- Показать оператор, если это не первое условие и есть более одного условия -->
                        <span v-if="condIndex < rule.conditions.length - 1">
                            <span v-if="rule.operator == 'AND'">И</span>
                            <span v-if="rule.operator == 'OR'">ИЛИ</span>
                        </span>
                        <p v-if="rule.answer_to_set">автоответ {{ rule.answer_to_set }}</p>
                </div>
                <Button @click="confirmRuleDeletion(rule.id)" icon="fa fa-trash" severity="link" class="p-button-danger rule-deletion" />
            </div>
        </div>
    </div>
  </div>
    <Button
      v-if="!showForm"
      type="button"
      label="Создать"
      @click="toggleForm"
      severity="primary"
    />
    <div v-if="showForm" class="create-rule-form">
      <h4 class="mt-2">Создание правила</h4>
      <form @submit.prevent="handleSubmit">
        <!-- Выбор типа правила -->
        <div class="form-group">
          <label for="ruleType">Тип правила:</label>
          <Select
            v-model="ruleType"
            id="ruleType"
            placeholder="Выберите тип"
            :options="ruleTypeOptions"
            option-label="label"
            option-value="value"
          />
        </div>
  
        <!-- Поле текста завершения -->
        <div v-if="ruleType === 'COMPLETE'" class="form-group">
          <label for="completionText">Текст завершения:</label>
          <Textarea v-model="completionText" id="completionText" rows="4" />
        </div>
  
        <!-- Поле для ответа при скрытии -->
        <div v-if="ruleType === 'HIDE'" class="form-group">
          <label for="answerToSet">и выбрать ответ</label>
          <Select
            v-model="answerToSet"
            id="answerToSet"
            placeholder="Нет ответа"
            :options="getAnswersOptions(question.id)"
            option-label="label"
            option-value="id"
          />
        </div>
  
        <!-- Условия -->
        <div class="conditions-group">
          <label>Условия</label>
          <div v-for="(conditionGroup, index) in conditionGroups" :key="index" class="condition-group">
            <div class="form-group">
              <label for="questionId">Вопрос:</label>
              <Select
                style="width:100%;font-size:1rem"
                v-model="conditionGroup.questionId"
                placeholder="Выберите вопрос"
                :options="questionOptions"
                option-label="label"
                option-value="value"
                :editable="true"
              />
            </div>
  
            <div class="form-group">
              <label for="conditionType">Тип условия:</label>
              <Select
                v-model="conditionGroup.conditionType"
                placeholder="Выберите тип"
                :options="conditionTypeOptions"
                option-label="label"
                option-value="value"
              />
            </div>
  
            <!-- Поле ответа SKU -->
            <div v-if="['SET', 'CONTAINS', 'EQUALS', 'LESS_THAN', 'GREATER_THAN', 'NOT_EQUALS'].includes(conditionGroup.conditionType)" class="form-group">
              <label for="answerSku">Ответ SKU:</label>
              <Select
                v-model="conditionGroup.answerSku"
                placeholder="Выберите ответ SKU"
                :options="getAnswersOptions(conditionGroup.questionId)"
                option-label="label"
                option-value="value"
              />
            </div>
  
            <!-- Поле значения -->
            <div v-if="['CONTAINS', 'EQUALS', 'LESS_THAN', 'GREATER_THAN', 'NOT_EQUALS'].includes(conditionGroup.conditionType)" class="form-group">
              <label for="conditionValue">Значение:</label>
              <InputText
                v-model="conditionGroup.value"
                :type="['LESS_THAN', 'GREATER_THAN'].includes(conditionGroup.conditionType) ? 'number' : 'text'"
              />
            </div>
  
                <div class="text-right">
                    <!-- Кнопка удаления условия -->
                    <Button
                    v-if="conditionGroups.length > 1"
                    type="button"
                    @click="removeCondition(index)"
                    icon="fa fa-trash"
                    severity="link" 
                    class="p-button-danger"
                    />
                </div>
            </div>

            <div class="flex justify-content-between">
            <div class="flex align-tems-center">
            <!-- Оператор между условиями -->
            <div v-if="conditionGroups.length < 2" class="form-group operator-group m-0">
              <Select
                v-model="operator"
                id="operator"
                placeholder="Выберите оператор"
                :options="operatorOptions"
                option-label="label"
                option-value="value"
              />
            </div>
            <!-- Кнопка добавления нового условия -->
            <Button
                v-if="conditionGroups.length < 2"
                type="button"
                label="+ ещё условие"
                @click="addCondition"
                severity="secondary"
                class="ml-2"
            />
            </div>

    
            <!-- Кнопка отправки формы -->
            <Button type="submit" label="Сохранить правило" class="p-button-primary" />
        </div>
        </div>
      </form>

    </div>

    <!-- Confirmation Dialog -->
    <Dialog v-model:visible="showConfirmRuleDialog" header="Подтверждение удаления" :modal="true" :closable="true">
      <p>Вы уверены, что хотите удалить это правило?</p>
      <div class="flex justify-content-between">
        <Button label="Отмена" @click="showConfirmRuleDialog = false" class="p-button-secondary" />
        <Button label="Удалить" @click="confirmRuleDeletionConfirmed" class="p-button-danger" style="color:white;" />
      </div>
    </Dialog>
</template>
  
  <script setup>
import { ref, defineProps, onMounted } from 'vue';
import { createRule, getAllQuestions, getRules, deleteRule  } from '../../services/apiService';
import Select from 'primevue/select';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import Dialog from 'primevue/dialog';

const props = defineProps({
  elementId: {
    type: Number,
    required: true
  },
  question: {
      type: Object,
      required: true
    }
});

const showForm = ref(false);
const ruleType = ref('');
const ruleToDelete = ref(null);
const showConfirmRuleDialog = ref(false);

const completionText = ref('');
const answerToSet = ref('');
const operator = ref('AND'); // Добавляем состояние для оператора
const rules = ref([]); // Состояние для хранения правил

const conditionGroups = ref([
  {
    questionId: '',
    conditionType: '',
    answerSku: '',
    value: '',
    operator: 'AND'
  }
]);
// const answersOptions = ref([]); // не помню зачему они тут:(
const questionOptions = ref([]);
const currentQuestionAnswers = ref({}); // Хранилище для ответов по текущему вопросу

const ruleTypeOptions = [
  { label: 'Показать текущий вопрос', value: 'SHOW' },
  { label: 'Скрыть текущий вопрос', value: 'HIDE' },
  { label: 'Завершить опрос', value: 'COMPLETE' }
];

const conditionTypeOptions = [
  { label: 'Существует', value: 'EXISTS' },
  { label: 'Установить', value: 'SET' },
  { label: 'Содержит', value: 'CONTAINS' },
  { label: 'Равно', value: 'EQUALS' },
  { label: 'Меньше', value: 'LESS_THAN' },
  { label: 'Больше', value: 'GREATER_THAN' },
  { label: 'Не равно', value: 'NOT_EQUALS' }
];

const operatorOptions = [
  { label: 'И', value: 'AND' },
  { label: 'ИЛИ', value: 'OR' }
];

const addCondition = () => {
  if (conditionGroups.value.length < 2) {
    conditionGroups.value.push({
      questionId: '',
      conditionType: '',
      answerSku: '',
      value: '',
      operator: 'AND'
    });
  }
};

const removeCondition = (index) => {
  if (conditionGroups.value.length > 1) {
    conditionGroups.value.splice(index, 1);
  }
};

const handleSubmit = async () => {
  try {
    const conditionsData = conditionGroups.value.map(cond => ({
      question: cond.questionId,
      condition_type: cond.conditionType,
      answer_sku: cond.answerSku || undefined,
      value: cond.value || undefined
    }));

    await createRule({
      element: props.elementId,
      rule_type: ruleType.value,
      conditions: conditionsData,
      completion_text: ruleType.value === 'COMPLETE' ? completionText.value : '',
      answer_to_set: ruleType.value === 'HIDE' ? answerToSet.value : '',
      operator: operator.value // Передача оператора из состояния
    });
    showForm.value = false;

    // Очистка данных формы
    ruleType.value = '';
    completionText.value = '';
    answerToSet.value = '';
    operator.value = 'AND';
    conditionGroups.value = [
      {
        questionId: '',
        conditionType: '',
        answerSku: '',
        value: '',
        operator: 'AND'
      }
    ];

    loadRules(); // Перезагрузка списка правил
    // Очистить форму или перенаправить пользователя
  } catch (error) {
    console.error('Ошибка при создании правила:', error);
    alert('Ошибка при создании правила');
  }
};

const loadRules = async () => {
  try {
    const response = await getRules(props.elementId);
    rules.value = response.data;
  } catch (error) {
    console.error('Ошибка при загрузке правил:', error);
  }
};

const loadQuestions = async () => {
  try {
    const response = await getAllQuestions();
    questionOptions.value = response.data.map(question => ({
      label: `(${question.question_sku}) ${question.title}`,
      value: question.id
    }));
    currentQuestionAnswers.value = response.data.reduce((acc, question) => {
      acc[question.id] = question.answers.map(answer => ({
        label: answer.title || 'Без названия',
        value: answer.answer_sku,
        id: answer.id
      }));
      return acc;
    }, {});
  } catch (error) {
    console.error('Ошибка при загрузке вопросов:', error);
  }
};

// Функция для получения опций ответов для выбранного вопроса
const getAnswersOptions = (questionId) => {
  return currentQuestionAnswers.value[questionId]?.map(answer => ({
    label: answer.label,
    value: answer.value,
    id: answer.id
  })) || [];
};

onMounted(() => {
  loadQuestions();
  loadRules(); // Загружаем правила при монтировании компонента

});

const toggleForm = () => {
  showForm.value = !showForm.value;
};

const formatConditions = (conditions) => {
  return conditions.map(cond => {
    return {
      question: cond.question.title, // Предполагаем, что условие содержит объект вопроса
      conditionType: cond.condition_type,
      answerSku: cond.answer_sku,
      value: cond.value,
      question_detail: cond.question_detail,
    };
  });
};

// Функция для открытия окна подтверждения удаления
const confirmRuleDeletion = (ruleId) => {
    console.log("Fired")
  ruleToDelete.value = ruleId;
  console.log(ruleToDelete.value)
  showConfirmRuleDialog.value = true;
  console.log(showConfirmRuleDialog.value)
};

// Функция для подтверждения удаления
const confirmRuleDeletionConfirmed = async () => {
  try {
    await deleteRule(ruleToDelete.value);
    showConfirmRuleDialog.value = false;
    loadRules();
  } catch (error) {
    console.error('Ошибка при удалении правила:', error);
    alert('Ошибка при удалении правила');
  }
};
</script>

  

  
  <style scoped>  
  .sub-text {
    font-size: 14px;
    font-style: italic;
    color: var(--p-inputtext-hover-border-color);
    margin-top: 0;
  }
  label {
    display: block;
    font-size: 14px;
    font-style: italic;
    color: var(--p-inputtext-hover-border-color);
    margin-top: 0;
  }
    h4 {
    margin: 0 0 5px 0;
    font-weight: 500;
  }
  .form-group {
    margin-bottom: 15px;
  }
  
  .condition-group {
    border: 1px solid var(--p-inputtext-border-color);
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
  }
  .p-button-danger {
  color: #dc3545!important;
}
.rule {
    border: 1px solid var(--p-inputtext-border-color);
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    position: relative;
}
.rule-deletion {
    position: absolute;
    top: 0;
    right: 0;
}
  </style>
  