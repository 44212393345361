<template>
  <div class="grid">
  <div class="col-6">
    <h3>Опросы</h3>
    <ul class="survey-list">
      <li v-for="survey in surveys" :key="survey.id" class="flex justify-content-between align-items-center">
        <div>
          <div>
            <router-link :to="`/surveys/${survey.id}`">{{ survey.title }}</router-link>
            <span>(CourseID:{{ survey.survey_id }}, ModuleID: {{ survey.survey_module }})</span>
          </div>
          <span class="m-0 subtext">Экранов: {{ survey.number_of_screens }}</span>
        </div>
        <div>
          <router-link :to="`/?courseId=${survey.survey_id}&moduleId=${survey.survey_module}&studentId=1&isCheck`" target="_blank"><Button severity="link" icon="fa fa-eye" /></router-link>
          <Button @click="openEditDialog(survey)" severity="link" icon="fa fa-edit" />
          <Button @click="confirmDeletion(survey.id)" class="delete-action" severity="link" icon="fa fa-trash" />
        </div>
      </li>
    </ul>
    <Button label="Создать" icon="fa fa-plus" @click="showCreateDialog = true" />
    <NewSurvey :visible="showCreateDialog" @update:visible="showCreateDialog = $event" @surveyCreated="fetchSurveys" />

    <div class="col-12 p-0">
      <h3>Прогресс студентов</h3>
      <ul class="progress-list">
        <li v-for="progress in studentProgress" :key="progress.unique_id" class="flex justify-content-between align-items-center">
          <div>
            <div>
              Студент: {{ progress.unique_id }} - Опрос: {{ progress.survey_title }}
            </div>
            <span class="m-0 subtext">Завершен: {{ progress.completed ? 'Да' : 'Нет' }}</span>
          </div>
          <div>
            <Button @click="loadStudentProgress(progress.unique_id, progress.survey_id)" severity="link" icon="fa fa-eye" />
          </div>
        </li>
      </ul>
    </div>

    <!-- Confirmation Dialog -->
    <Dialog 
      v-model:visible="showConfirmDialog" 
      header="Подтверждение удаления" 
      :modal="true" 
      :closable="false"
    >
      <p>Вы уверены, что хотите удалить этот опрос?</p>
      <div class="flex justify-content-between">
        <Button label="Отмена" @click="showConfirmDialog = false" class="p-button-secondary" />
        <Button label="Удалить" @click="confirmDeletionConfirmed" class="p-button-danger" />
      </div>
    </Dialog>

    <!-- Edit Survey Dialog -->
    <Dialog 
      v-model:visible="showEditDialog" 
      header="Редактировать опрос" 
      :modal="true" 
      :closable="false"
       class="edit-survey"
    >
      <div class="p-field">
        <label for="edit-title">Название</label>
        <InputText type="text" v-model="editSurvey.title" id="edit-title" />
      </div>
      <div class="p-field">
        <label for="edit-id">ID курса</label>
        <InputText type="text" v-model="editSurvey.survey_id" id="edit-id" />
      </div>
      <div class="p-field">
        <label for="edit-id">ID модуля</label>
        <InputText type="text" v-model="editSurvey.survey_module" id="edit-id" />
      </div>
      <div class="flex justify-content-between">
        <Button label="Отмена" @click="showEditDialog = false" class="p-button-secondary" />
        <Button label="Сохранить" @click="surveyUpdate" class="p-button-success" />
      </div>
    </Dialog>

    <!-- Student Progress -->
    <Dialog v-model:visible="showProgressDialog" header="Прогресс студента" :modal="true" :closable="true" style="width:750px;">
      <ul class="progress-list" v-if="studentAnswers.length">
        <li v-for="answer in studentAnswers" :key="answer.question_title">
          <b class="mb-1 block">{{ answer.question_title }}</b>
          <p v-if="answer.child_answers && !answer.child_answers.length" class="mb-0 mt-0">{{ answer.answer_value || 'Ответ не указан' }}</p>
          <!-- Отображаем дочерние вопросы -->
          <ul class="progress-list" v-if="answer.child_answers && answer.child_answers.length" >
            <li v-for="child in answer.child_answers" :key="child.question_title" class="ml-3 flex align-items-center">
              <b>{{ child.question_title }}:</b>
              <p  v-if="child.grid_answers.length === 0"  class="mb-0 mt-0 ml-2">{{ child.answer_value || 'Ответ не указан' }}</p>
                <ul v-if="child.grid_answers.length" class="progress-list" style="display:block;">
                  <li v-for="grid in child.grid_answers" :key="grid.id" class="ml-2"><b>{{grid.column_title}}</b> - {{grid.answer_text}}</li>
                </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p v-else>Ответы отсутствуют.</p>
    </Dialog>
  </div>
  <div class="col-6">
  <FileUpload />
  </div>

</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getSurveys, deleteSurvey, updateSurvey, getStudentProgress, getStudentSurveyAnswers } from '../../services/apiService';
import NewSurvey from '../../components/admin-editor/NewSurvey.vue';
import FileUpload from '../../components/admin-editor/FileUpload.vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';

const surveys = ref([]);
const studentProgress = ref([]);
const studentAnswers = ref([]);
const showProgressDialog = ref(false);
const showCreateDialog = ref(false);
const showConfirmDialog = ref(false);
const showEditDialog = ref(false);
const surveyToDelete = ref(null);
const editSurvey = ref({ id: '', title: '', survey_id: '', survey_module: '' });

const fetchSurveys = async () => {
  try {
    const response = await getSurveys();
    surveys.value = response.data;
  } catch (error) {
    console.error('Error fetching surveys:', error);
  }
};

const fetchStudentProgress = async () => {
  try {
    const response = await getStudentProgress();
    studentProgress.value = response.data;
  } catch (error) {
    console.error('Error fetching student progress:', error);
  }
};

const loadStudentProgress = async (studentId, surveyId) => {
  try {
    const response = await getStudentSurveyAnswers(studentId, surveyId);
    studentAnswers.value = response.data;
    showProgressDialog.value = true;
  } catch (error) {
    console.error('Error fetching student answers:', error);
  }
};

const confirmDeletion = (id) => {
  surveyToDelete.value = id;
  showConfirmDialog.value = true;
};

const confirmDeletionConfirmed = async () => {
  try {
    await deleteSurvey(surveyToDelete.value);
    fetchSurveys();
  } catch (error) {
    console.error('Error deleting survey:', error);
  } finally {
    showConfirmDialog.value = false;
    surveyToDelete.value = null;
  }
};

const openEditDialog = (survey) => {
  editSurvey.value = { ...survey };
  showEditDialog.value = true;
};

const surveyUpdate = async () => {
  try {
    await updateSurvey(editSurvey.value.id, editSurvey.value);
    fetchSurveys();
  } catch (error) {
    console.error('Error updating survey:', error);
  } finally {
    showEditDialog.value = false;
    editSurvey.value = { id: '', title: '', survey_id: '', survey_module: '' };
  }
};

onMounted(() => {
  fetchSurveys();
  fetchStudentProgress();
});
</script>

<style scoped>
.survey-list, .progress-list {
  list-style: none;
  padding: 0;
}
.subtext {
  font-size: 0.7rem!important;
}
.survey-list li, .progress-list li {
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 10px;
}
.survey-list li a, .progress-list li a {
  text-decoration: none;
  color: var(--p-button-primary-background);
  font-weight: 600;
}
.survey-list li span, .progress-list li span {
  font-size: 14px;
  margin-left: 10px;
  color: #6c757d;
}
.delete-action {
  color: #dc3545;
}
.edit-survey label {
    display:block;
}
.p-field {
    margin-bottom: 1rem;
}
</style>
